export const mapConstants = {

  //Satellite Data
  SateliteGetAllRequest : "SATELITE_GETALL_REQUEST", 
  SateliteGetAllSuccess : "SATELITE_GETALL_SUCCESS", 
  SateliteGetAllFailure : "SATELITE_GETALL_FAILURE", 

  //Layer Data
  LayerGetAllRequest : "LAYER_GETALL_REQUEST",
  LayerGetAllSuccess : "LAYER_GETALL_SUCCESS",
  LayerGetAllFailure : "LAYER_GETALL_FAILURE",

  //Sign-in Data
  SigninGetAllRequest : "SIGNIN_GETALL_REQUEST",
  SigninGetAllSuccess : "SIGNIN_GETALL_SUCCESS",
  SigninGetAllFailure : "SIGNIN_GETALL_FAILURE",

  //favorite details Data
  FavoriteGetAllRequest : "FAVORITE_GETALL_REQUEST",
  FavoriteGetAllSuccess : "FAVORITE_GETALL_SUCCESS",
  FavoriteGetAllFailure : "FAVORITE_GETALL_FAILURE",

  //users Area of interest
  userareaGetAllRequest : "USERAREA_GETALL_REQUEST",
  userareaGetAllSuccess : "USERAREA_GETALL_SUCCESS",
  userareaGetAllFailure : "USERAREA_GETALL_FAILURE",

  //Load the AOI from E-mail 
  EmailGetAllRequest : "EMAIL_GETALL_REQUEST",
  EmailGetAllSuccess : "EMAIL_GETALL_SUCCESS",
  EmailGetAllFailure : "EMAIL_GETALL_FAILURE",
  
  EmailFieldGetAllRequest : "EMAIL_FIELD_GETALL_REQUEST",
  EmailFieldGetAllSuccess : "EMAIL_FIELD_GETALL_SUCCESS",
  EmailFieldGetAllFailure : "EMAIL_FIELD_GETALL_FAILURE",

  SeasonAllRequest : "SEASON_GETALL_REQUEST",
  SeasonAllSuccess : "SEASON_GETALL_SUCCESS",
  SeasonAllFailure : "SEASON_GETALL_FAILURE",

  SeasonGetallRequest : "SEASONGET_ALL_REQUEST",
  SeasonGetallSuccess : "SEASONGET_ALL_SUCCESS",
  SeasonGetallFailure : "SEASONGET_ALL_FAILURE",

  MapstateAllRequest : "MAPSTATE_GETALL_REQUEST",
  MapstateAllSuccess : "MAPSTATE_GETALL_SUCCESS",
  MapstateAllFailure : "MAPSTATE_GETALL_FAILURE",

  SeasonDeleteAllRequest : "SEASON_DELETE_GETALL_REQUEST",
  SeasonDeleteAllSuccess : "SEASON_DELETE_GETALL_SUCCESS",
  SeasonDeleteAllFailure : "SEASON_DELETE_GETALL_FAILURE",

  SentinelDataAllRequest : "SENTINEL_GETALL_REQUEST",
  SentinelDataAllSuccess : "SENTINEL_GETALL_SUCCESS",
  SentinelDataAllFailure : "SENTINEL_GETALL_FAILURE",

  MapCoordinateAllRequest : "COORDINATE_DATA_ALLREQUEST",
  MapCoordinateAllSuccess : "COORDINATE_DATA_ALLSUCCESS",
  MapCoordinateAllFailure : "COORDINATE_DATA_ALLFAILURE",

  MyFarmPanelAllRequest : "MYFARM_PANEL_ALLREQUEST",
  MyFarmPanelAllSuccess : "MYFARM_PANEL_ALLSUCCESS",
  MyFarmPanelAllFailure : "MYFARM_PANEL_ALLFAILURE",

}