

export default function Component({ selectedDate, onDateChange, rangeDateto , onDateChangeto ,onChangeDateclear  }){

  const handleDateChange = (event) => {
    onDateChangeto(event.target.value);
    sessionStorage.setItem('to',event.target.value);
  };

  const handleDateChangefrom = (event) => {
    onDateChange(event.target.value);
    sessionStorage.setItem('from',event.target.value);
  };



    return(   
      <>  
      {<div className="daterange-filter-container" >
            <div className='daterange-filter'>
              <div className='daterange-label'>
                  <label>From</label>
              </div>             
              <input  type='date' id="from_date" className="date-from-value" value={selectedDate} onChange={handleDateChangefrom}  />
            </div>
            <div className='daterange-filter'>
               <div className='daterange-label'>
                  <label>To</label>
              </div> 
              <input type='date' id="to_date" className="date-to-value" value={rangeDateto} onChange={handleDateChange}  />
            </div>
        </div> }
        </> 
    );
}