export const PROD_BASE_URL = "https://as-terramate-webapi-prod.azurewebsites.net/api";
export const TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJrZXkiOiJyZWFjdEFwcCIsImlhdCI6MTY5NjkyODIwOX0.v9EUrZHyZZPDdbKf2PpFkKtMr8MrmuxFkupACSE1itc';
export const SENTINEL_TOKEN ='eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ3dE9hV1o2aFJJeUowbGlsYXctcWd4NzlUdm1hX3ZKZlNuMW1WNm5HX0tVIn0.eyJleHAiOjE3MDkxODQ1NjYsImlhdCI6MTcwOTE4MDk2NiwianRpIjoiNTRmNmNhNzgtODU5MS00ZWM3LWFhMzEtNDY3YmI3MmUwNGE1IiwiaXNzIjoiaHR0cHM6Ly9zZXJ2aWNlcy5zZW50aW5lbC1odWIuY29tL2F1dGgvcmVhbG1zL21haW4iLCJzdWIiOiI3NTVlNjA0NC1mMzljLTRjZGQtOWEzZC04OGE2ZmY1NzQ3YWIiLCJ0eXAiOiJCZWFyZXIiLCJhenAiOiIwNmE5MzdhYy1hOGZjLTQyMjYtODUzMi1jYWU5MDUzODJlZmEiLCJzY29wZSI6ImVtYWlsIHByb2ZpbGUiLCJjbGllbnRJZCI6IjA2YTkzN2FjLWE4ZmMtNDIyNi04NTMyLWNhZTkwNTM4MmVmYSIsImNsaWVudEhvc3QiOiIyMDIuODguMjA5LjExNCIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwicHJlZmVycmVkX3VzZXJuYW1lIjoic2VydmljZS1hY2NvdW50LTA2YTkzN2FjLWE4ZmMtNDIyNi04NTMyLWNhZTkwNTM4MmVmYSIsImNsaWVudEFkZHJlc3MiOiIyMDIuODguMjA5LjExNCIsImFjY291bnQiOiJlNTY0YjIzNC1mZGJkLTQxNDctYTI1YS1hZTlkOWM3OTQ4Y2EifQ.fS3QTA-jZPS-VoHPWU-seMabH06xHqBCCIodVz99bpvtwLUUniK-q84Av5xcaFCGrYZw3pU67ovXdvGvWzobjNlQB_YEGSUSjemVGiKHS8YjKaDzHG5Z-_ejj1LWMIils2H3cXWYI-Y3oOOzY_0YnH9v347O2zF14aoi2Bz4wSWv-zd5P3Sjzu9UpJWu1SsEbVDKfJR8RK5Umm_5LSRRqEPfnIuvUPAy-WKloz7UPX7_a8vRaAFLYVDW8qy5873ZnCvqDaG8AJa0tD-0B7W1oATtvsBXs7P6pM9cn_fWfy5RkoiGPP1Ov84cCsZlVw7-Koo7KHyjcMWwsA0pCOqiXg';

//azure  
export const CONTAINER_NAME = `terramate-ai`;
export const VIEW_KEY = `cES8wdjWJA5W+exA529kHQiUyIKLD3TaOBMeBmJKpwui7JmwzIhqtaHdq6R/1kPQhbY7k7PHdSMT+AStBaWxrw==`;
export const PROJECT = 'terramate';
export const MAPURL = 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}';
export const CROPROTATION = "Keep record of a crop rotation history and plan the future sowing operations to maintain soil fertility";

//toast message
export const SOMETHING_TOAST = "Something went wrong";
export const LOGIN_TOAST = "Login successfully";
export const TOAST_POSITION = 'top-center';
export const TOAST_NDVI_ERROR = 'No Data Available';
export const TOAST_NDVI_SUCCESS = 'Layer Applied';
export const TOAST_DATE_RANGE = 'Invalid Date Range';
export const TOAST_INVALID_RESULT = "Upload the Image to see Result";
export const TOAST_INVALID_IMG = 'invalid Image';
export const ERROR_MESSAGE_IDENTIFY = "An error occurred. Unable to load data";
export const ERROR_LAYER_SELECTION ="No layer selected. Please choose one";
export const MESSAGE_NO_SEASON = "No season created? To create a new season, click on the";

//Sentinel Hub
export const baseUrl = 'https://services.sentinel-hub.com/ogc/wms/61ed7b70-26c6-4164-b11b-6ed0f5a93b20?REQUEST=GetMap&LAYERS=NATURAL-COLOR&WIDTH=512&HEIGHT=343';
export const evalScriptNDVI = 'EVALSCRIPT=bGV0IG5kdmkgPSAoQjA4IC0gQjA0KSAvIChCMDggKyBCMDQpOwpyZXR1cm4gY29sb3JCbGVuZChuZHZpLAogICBbLTAuMiwgMCwgMC4xLCAwLjIsIDAuMywgMC40LCAwLjUsIDAuNiwgMC43LCAwLjgsIDAuOSwgMS4wIF0sCiAgIFtbMCwgMCwgMCxkYXRhTWFza10sCQkJCQkJCSAKICAgIFsxNjUvMjU1LDAsMzgvMjU1LGRhdGFNYXNrXSwgICAgICAgCiAgICBbMjE1LzI1NSw0OC8yNTUsMzkvMjU1LGRhdGFNYXNrXSwgCiAgICBbMjQ0LzI1NSwxMDkvMjU1LDY3LzI1NSxkYXRhTWFza10sICAKICAgIFsyNTMvMjU1LDE3NC8yNTUsOTcvMjU1LGRhdGFNYXNrXSwgCiAgICBbMjU0LzI1NSwyMjQvMjU1LDEzOS8yNTUsZGF0YU1hc2tdLCAKICAgIFsyNTUvMjU1LDI1NS8yNTUsMTkxLzI1NSxkYXRhTWFza10sCiAgICBbMjE3LzI1NSwyMzkvMjU1LDEzOS8yNTUsZGF0YU1hc2tdLCAKICAgIFsxNjYvMjU1LDIxNy8yNTUsMTA2LzI1NSxkYXRhTWFza10sCiAgICBbMTAyLzI1NSwxODkvMjU1LDk5LzI1NSxkYXRhTWFza10sICAKICAgIFsyNi8yNTUsMTUyLzI1NSw4MC8yNTUsZGF0YU1hc2tdLCAgCiAgICBbMCwxMDQvMjU1LDU1LzI1NSxkYXRhTWFza10gICAgICAgIAogICBdKTs=';
export const evalScriptEVI = "EVALSCRIPT=Ly9WRVJTSU9OPTMNCi8vIEVuaGFuY2VkIFZlZ2V0YXRpb24gSW5kZXggIChhYmJydi4gRVZJKQ0KLy8gR2VuZXJhbCBmb3JtdWxhOiAyLjUgKiAoTklSIC0gUkVEKSAvICgoTklSICsgNipSRUQgLSA3LjUqQkxVRSkgKyAxKQ0KLy8gVVJMIGh0dHBzOi8vd3d3LmluZGV4ZGF0YWJhc2UuZGUvZGIvc2ktc2luZ2xlLnBocD9zZW5zb3JfaWQ9OTYmcnNpbmRleF9pZD0xNg0KZnVuY3Rpb24gc2V0dXAoKSB7DQogICByZXR1cm4gew0KICAgICAgaW5wdXQ6IFsiQjAyIiwgIkIwNCIsICJCMDgiLCAiZGF0YU1hc2siXSwNCiAgICAgIG91dHB1dDogeyBiYW5kczogNCB9DQogICB9Ow0KfQ0KDQpjb25zdCByYW1wID0gWw0KICAgWy0wLjUsIDB4MGMwYzBjXSwNCiAgIFstMC4yLCAweGJmYmZiZl0sDQogICBbLTAuMSwgMHhkYmRiZGJdLA0KICAgWzAsIDB4ZWFlYWVhXSwNCiAgIFswLjAyNSwgMHhmZmY5Y2NdLA0KICAgWzAuMDUsIDB4ZWRlOGI1XSwNCiAgIFswLjA3NSwgMHhkZGQ4OWJdLA0KICAgWzAuMSwgMHhjY2M2ODJdLA0KICAgWzAuMTI1LCAweGJjYjc2Yl0sDQogICBbMC4xNSwgMHhhZmMxNjBdLA0KICAgWzAuMTc1LCAweGEzY2M1OV0sDQogICBbMC4yLCAweDkxYmY1MV0sDQogICBbMC4yNSwgMHg3ZmIyNDddLA0KICAgWzAuMywgMHg3MGEzM2ZdLA0KICAgWzAuMzUsIDB4NjA5NjM1XSwNCiAgIFswLjQsIDB4NGY4OTJkXSwNCiAgIFswLjQ1LCAweDNmN2MyM10sDQogICBbMC41LCAweDMwNmQxY10sDQogICBbMC41NSwgMHgyMTYwMTFdLA0KICAgWzAuNiwgMHgwZjU0MGFdLA0KICAgWzEsIDB4MDA0NDAwXSwNCl07DQoNCmNvbnN0IHZpc3VhbGl6ZXIgPSBuZXcgQ29sb3JSYW1wVmlzdWFsaXplcihyYW1wKTsNCg0KZnVuY3Rpb24gZXZhbHVhdGVQaXhlbChzYW1wbGVzKSB7DQogICBsZXQgZXZpID0gMi41ICogKHNhbXBsZXMuQjA4IC0gc2FtcGxlcy5CMDQpIC8gKChzYW1wbGVzLkIwOCArIDYuMCAqIHNhbXBsZXMuQjA0IC0gNy41ICogc2FtcGxlcy5CMDIpICsgMS4wKTsNCiAgIGxldCBpbWdWYWxzID0gdmlzdWFsaXplci5wcm9jZXNzKGV2aSk7DQogICByZXR1cm4gaW1nVmFscy5jb25jYXQoc2FtcGxlcy5kYXRhTWFzaykNCn0=";
export const evalScriptGNDVI = "EVALSCRIPT=Ly8gR3JlZW4gTm9ybWFsaXplZCBEaWZmZXJlbmNlIFZlZ2V0YXRpb24gSW5kZXggICAoYWJicnYuIEdORFZJKQ0KLy8gR2VuZXJhbCBmb3JtdWxhOiAoTklSIC0gWzU0MDo1NzBdKSAvIChOSVIgKyBbNTQwOjU3MF0pDQovLyBVUkwgaHR0cHM6Ly93d3cuaW5kZXhkYXRhYmFzZS5kZS9kYi9zaS1zaW5nbGUucGhwP3NlbnNvcl9pZD05NiZyc2luZGV4X2lkPTI4DQoNCmNvbnN0IGNvbG9yUmFtcCA9IFtbMCwweDAwMDAwMF0sWzEsMHhmZmZmZmZdXQ0KbGV0IHZpeiA9IG5ldyBDb2xvclJhbXBWaXN1YWxpemVyKGNvbG9yUmFtcCk7DQpmdW5jdGlvbiBzZXR1cCgpIHsNCiAgcmV0dXJuIHsNCiAgICBpbnB1dDogWyJCMDgiLCJCMDMiLCAiZGF0YU1hc2siXSwNCiAgICBvdXRwdXQ6IFsNCiAgICAgIHsgaWQ6ImRlZmF1bHQiLCBiYW5kczogNCB9LA0KICAgICAgeyBpZDogImluZGV4IiwgYmFuZHM6IDEsIHNhbXBsZVR5cGU6ICdGTE9BVDMyJyB9LA0KICAgICAgeyBpZDogImVvYnJvd3NlclN0YXRzIiwgYmFuZHM6IDIsIHNhbXBsZVR5cGU6ICdGTE9BVDMyJyB9LA0KICAgICAgeyBpZDogImRhdGFNYXNrIiwgYmFuZHM6IDEgfQ0KICAgIF0NCiAgfTsNCn0NCg0KZnVuY3Rpb24gZXZhbHVhdGVQaXhlbChzYW1wbGVzKSB7DQogIGxldCBpbmRleCA9IChzYW1wbGVzLkIwOC1zYW1wbGVzLkIwMykvKHNhbXBsZXMuQjA4K3NhbXBsZXMuQjAzKTsNCiAgY29uc3QgbWluSW5kZXggPSAwOw0KICBjb25zdCBtYXhJbmRleCA9IDE7DQogIGxldCB2aXNWYWwgPSBudWxsOw0KDQogIGlmKGluZGV4ID4gbWF4SW5kZXggfHwgaW5kZXggPCBtaW5JbmRleCkgew0KICAgIHZpc1ZhbCA9IFswLCAwLCAwLCAwXTsNCiAgfQ0KICBlbHNlIHsNCiAgICB2aXNWYWwgPSBbLi4udml6LnByb2Nlc3MoaW5kZXgpLHNhbXBsZXMuZGF0YU1hc2tdOw0KICB9DQogDQogIGNvbnN0IGluZGV4VmFsID0gc2FtcGxlcy5kYXRhTWFzayA9PT0gMSA/IGluZGV4IDogTmFOOw0KDQogIHJldHVybiB7IA0KICAgIGRlZmF1bHQ6IHZpc1ZhbCwNCiAgICBpbmRleDogW2luZGV4VmFsXSwNCiAgICBlb2Jyb3dzZXJTdGF0czogW2luZGV4VmFsLHNhbXBsZXMuZGF0YU1hc2tdLA0KICAgIGRhdGFNYXNrOiBbc2FtcGxlcy5kYXRhTWFza10NCiAgfTsNCn0=";
export const evalScriptSAVI = "EVALSCRIPT=Ly8gU29pbCBBZGp1c3RlZCBWZWdldGF0aW9uIEluZGV4ICAoYWJicnYuIFNBVkkpDQovLyBHZW5lcmFsIGZvcm11bGE6ICg4MDBubSAtIDY3MG5tKSAvICg4MDBubSArIDY3MG5tICsgTCkgKiAoMSArIEwpDQovLyBVUkwgaHR0cHM6Ly93d3cuaW5kZXhkYXRhYmFzZS5kZS9kYi9zaS1zaW5nbGUucGhwP3NlbnNvcl9pZD05NiZyc2luZGV4X2lkPTg3DQpmdW5jdGlvbiBzZXR1cCgpIHsNCiAgIHJldHVybiB7DQogICAgICBpbnB1dDogWyJCMDQiLCAiQjA4IiwgImRhdGFNYXNrIl0sDQogICAgICBvdXRwdXQ6IHsgYmFuZHM6IDQgfQ0KICAgfTsNCn0NCg0KbGV0IEwgPSAwLjQyODsgLy8gTCA9IHNvaWwgYnJpZ2h0bmVzcyBjb3JyZWN0aW9uIGZhY3RvciBjb3VsZCByYW5nZSBmcm9tICgwIC0xKQ0KDQpjb25zdCByYW1wID0gWw0KICAgWy0wLjUsIDB4MGMwYzBjXSwNCiAgIFstMC4yLCAweGJmYmZiZl0sDQogICBbLTAuMSwgMHhkYmRiZGJdLA0KICAgWzAsIDB4ZWFlYWVhXSwNCiAgIFswLjAyNSwgMHhmZmY5Y2NdLA0KICAgWzAuMDUsIDB4ZWRlOGI1XSwNCiAgIFswLjA3NSwgMHhkZGQ4OWJdLA0KICAgWzAuMSwgMHhjY2M2ODJdLA0KICAgWzAuMTI1LCAweGJjYjc2Yl0sDQogICBbMC4xNSwgMHhhZmMxNjBdLA0KICAgWzAuMTc1LCAweGEzY2M1OV0sDQogICBbMC4yLCAweDkxYmY1MV0sDQogICBbMC4yNSwgMHg3ZmIyNDddLA0KICAgWzAuMywgMHg3MGEzM2ZdLA0KICAgWzAuMzUsIDB4NjA5NjM1XSwNCiAgIFswLjQsIDB4NGY4OTJkXSwNCiAgIFswLjQ1LCAweDNmN2MyM10sDQogICBbMC41LCAweDMwNmQxY10sDQogICBbMC41NSwgMHgyMTYwMTFdLA0KICAgWzAuNiwgMHgwZjU0MGFdLA0KICAgWzEsIDB4MDA0NDAwXSwNCl07DQoNCmNvbnN0IHZpc3VhbGl6ZXIgPSBuZXcgQ29sb3JSYW1wVmlzdWFsaXplcihyYW1wKTsNCg0KZnVuY3Rpb24gZXZhbHVhdGVQaXhlbChzYW1wbGVzKSB7DQogICBjb25zdCBpbmRleCA9IChzYW1wbGVzLkIwOCAtIHNhbXBsZXMuQjA0KSAvIChzYW1wbGVzLkIwOCArIHNhbXBsZXMuQjA0ICsgTCkgKiAoMS4wICsgTCk7DQogICBsZXQgaW1nVmFscyA9IHZpc3VhbGl6ZXIucHJvY2VzcyhpbmRleCk7DQogICByZXR1cm4gaW1nVmFscy5jb25jYXQoc2FtcGxlcy5kYXRhTWFzaykNCn0=";
export const quality = '&QUALITY=70';
