import Container from "./Container/Container";
import "./App.css";
import { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { FieldList } from "./map/CropMonitor/FieldList";
import Cropidentify from "./map/CropManagement/cropIdentify"; 
import DrawingShape from "./map/Component/drawShape";
import { FarmPanel } from "./map/CropMonitor/farmPanel";
import Cropgrowth from "./map/CropManagement/cropGrowth";
import Filters from "./map/Component/Filters";
import FieldSeason from "./map/CropMonitor/Season/SeasonFieldList";
import AreaOfInterest from "./map/Component/AreaOfInterest";
import Landing from "./Container/Landing";
import SidePanel from "./Component/sidePanel";


export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate to="/terramate" />} />
          <Route path="/terramate" element={<Landing />} />
          <Route path="/app" element={ <Container/>}>
            <Route path="listAll" element={<FieldList />} />
            <Route path="crop-identify" element={<Cropidentify />} />
            <Route path="crop-growth" element={<Cropgrowth />} />
            <Route path="aoi/draw" element={ <SidePanel /> }/>
            <Route path="myFarm/draw" element={<FarmPanel />}/>
            <Route path="shape" element={<DrawingShape map={null} />} />
            <Route path="filter/:name" element={<Filters />} />
            <Route path="field/:name" element={<FieldSeason />} />
            <Route path="tracking" element={<AreaOfInterest map={null} />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
