import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from "luxon";


import { sowingActions } from '../../actions/sowingActions';

function Sowingform({ onInputValueChange, fieldvalue, season, sowingstate, Callbackdata , isvalid, deleted}) {


   const sowingIrrigation = useSelector((state: any) => state.sowingReducer.irrigationData);
   const sowingTillage = useSelector((state: any) => state.sowingTillreducer.tillageData);
   const sowingCrop = useSelector((state: any) => state.sowingCropreducer.cropData);
   const matureCrop = useSelector((state: any) => state.cropmatureReducer.matureData);
   const dispatch = useDispatch();

   const [Cropname, setselectedCropname] = useState((sowingstate === undefined || sowingstate.sowing_crop_id === undefined) ? "65f04e2fc66623a69b8124d3" : sowingstate.sowing_crop_id);
   const [Irrigation, setselectedIrrigation] = useState((sowingstate === undefined || sowingstate.sowing_irrigation_type_id === undefined) ? "65f04fcfc66623a69b8124d7" : sowingstate.sowing_irrigation_type_id);
   const [Tillage, setselectedTillage] = useState((sowingstate === undefined || sowingstate.sowing_tillage_type_id === undefined) ? "65f05055c66623a69b8124db" : sowingstate.sowing_tillage_type_id);
   const [actualyield, setActualyield] = useState((sowingstate === undefined) ? "" : sowingstate.sowing_actual_yield);
   const [targetyield, setTargetyield] = useState((sowingstate === undefined ) ? "" : sowingstate.sowing_target_yield);
   const [notes, setNotes] = useState((sowingstate === undefined || sowingstate.sowing_notes === '') ? "" : sowingstate.sowing_notes);
   const [harvestdate, setHarvestdate] = useState((sowingstate === undefined || sowingstate.sowing_harvest_date === undefined || sowingstate.sowing_harvest_date === null || sowingstate.sowing_harvest_date === "") ? null : (sowingstate._id !== undefined) ? DateTime.fromISO(new Date(sowingstate.sowing_harvest_date).toISOString()).toISODate() : sowingstate.sowing_harvest_date);
   const [sowingdate, setSowingdate] = useState((sowingstate === undefined || sowingstate.sowing_date === undefined || sowingstate.sowing_date === null || sowingstate.sowing_date === "" ) ? null : (sowingstate._id !== undefined) ? DateTime.fromISO(new Date(sowingstate.sowing_date).toISOString()).toISODate() : sowingstate.sowing_date);
   const [maturity, setselectedMaturity] = useState((sowingstate === undefined || sowingstate.sowing_crop_type_id === undefined || sowingstate.sowing_crop_type_id === "") ? "" : (sowingstate.sowing_crop_type_id._id) ? ((sowingstate.sowing_crop_type_id === null || sowingstate.sowing_crop_type_id === "") ? "" : (sowingstate.sowing_crop_type_id._id)) :(sowingstate.sowing_crop_type_id));
   const [index, setSelectedIndex] = useState((sowingstate === undefined || sowingstate.index === undefined) ? "" : sowingstate.index);
   const [accsowingState, setAccsowingstate] = useState(sowingstate || {});
   
   
   useEffect(()=>{

      if (!sowingstate) return;

      if((sowingstate.sowing_crop_id === '') &&( sowingstate.sowing_irrigation_type_id === '') && (sowingstate.sowing_tillage_type_id === '') && (sowingstate.sowing_actual_yield === '')
         && (sowingstate.sowing_target_yield === '') && (sowingstate.sowing_notes === '') && (sowingstate.sowing_harvest_date === '') && (sowingstate.sowing_date === '') && 
         (sowingstate.sowing_crop_type_id === '') ){
          setNotes(''); setHarvestdate(''); setselectedTillage(""); 
          setActualyield(''); setTargetyield(''); setselectedIrrigation('');
          setselectedCropname(''); setSowingdate('');
          setselectedMaturity("");
          setSelectedIndex((sowingstate === undefined || sowingstate.index === undefined) ? "" : sowingstate.index);
          setAccsowingstate(sowingstate);
      }
   },[sowingstate]);

   const Propssend = () => {
      setAccsowingstate({
         ...accsowingState,
         sowing_crop_id: Cropname,
         sowing_crop_type_id: maturity,
         sowing_irrigation_type_id: Irrigation,
         sowing_tillage_type_id: Tillage,
         sowing_field_id: fieldvalue,
         sowing_user_id: localStorage.getItem('client_id'),
         sowing_season_id: season,
         sowing_harvest_date: harvestdate,
         sowing_target_yield: targetyield,
         sowing_actual_yield: actualyield,
         sowing_notes: notes,
         sowing_date: sowingdate,
         index: index,
      })
   }

   useEffect(() => {
      if (Cropname || Irrigation || Tillage || actualyield || targetyield || notes || harvestdate || sowingdate || maturity) {
         Propssend();
      }
   }, [Cropname, Irrigation, Tillage, actualyield, targetyield, notes, harvestdate, sowingdate, maturity]);

   useEffect(()=>{
      Callbackdata(accsowingState);
   },[accsowingState]);

   onInputValueChange(accsowingState);

   useEffect(() => {
      if (maturity !== "" && maturity !== undefined) {
         dispatch(sowingActions.Croptypemature(Cropname));
      }
   }, [maturity !== "" && maturity !== undefined]);


   const handleChangeCrop = async (event) => {
      setselectedMaturity("");  
      setselectedCropname(event.target.value);
      dispatch(sowingActions.Croptypemature(event.target.value));
   };

   const handleChangeIrrigation = (event) => {
      setselectedIrrigation(event.target.value);
   };

   const handleChangeTillage = (event) => {
      setselectedTillage(event.target.value);
   };

   const handlechangematurity = (event) => {
      setselectedMaturity(event.target.value);
   }
   
   const OnchangeActualYield = (event) => {
      setActualyield(event.target.value)
   }

   const OnchangeTargetYield = (event) => {
      setTargetyield(event.target.value);
   }

   const OnchangeNotes = (event) => {
      setNotes(event.target.value);
   }

   const OnChangeHarvestdate = (event) => {
      setHarvestdate(event.target.value);
   }

   const OnChangeSowingdate = (event) => {
      setSowingdate(event.target.value);
   }



   return (
      <>
         <div className="container1">
            <div className='first-last-container'>
               <div className='nest-container-first' >
                  <label className="label-text-sowing">Crop name</label>
                  <div className="inputContainer">
                     <select className='custom-select' value={Cropname} onChange={handleChangeCrop}>
                        <option value="" disabled selected>select your option</option>
                        {sowingCrop && sowingCrop.map((item: any, i: any) => (<option value={item._id} key={i}>{item.crop_name}</option>))}
                     </select>
                     
                  </div>
                  {isvalid == "invalid" && Cropname === "" ? <label className="required-label">* required</label> : "" }
               </div>
               <div className='nest-container-last'>
                  <label className="label-text-sowing">Maturity</label>
                  <div className="inputContainer">
                     <select className='custom-select'  value={maturity} onChange={handlechangematurity} >
                        <option value="" disabled selected>select your option</option>
                        {matureCrop && matureCrop.map((item: any, i: any) => (
                           <option value={item._id} key={i}>{item.crop_type_maturity}</option>))}
                     </select>
                  </div>
                  {isvalid == "invalid" && maturity === "" ? <label className="required-label" >* required</label> : "" }
               </div>
            </div>

            <div className='first-last-container'>
               <div className='nest-container-first' >
                  <label className="label-text-sowing">Sowing date</label>
                  <div className="inputContainer">
                     <input type="date" className="input-Field" value={sowingdate} onChange={OnChangeSowingdate} />
                  </div>
                  {isvalid == "invalid" && sowingdate === "" ? <label className="required-label" >* required</label> : "" }
               </div>

               <div className='nest-container-last'>
                  <label className="label-text-sowing">Harvesting date</label>
                  <div className="inputContainer">
                     <input type="date" className="input-Field" value={harvestdate} onChange={OnChangeHarvestdate} />
                  </div>
                  {isvalid == "invalid" && harvestdate === "" ? <label className="required-label" >* required</label> : "" }
               </div>
            </div>

            <div className='first-last-container'>
               <div className='nest-container-first'>
                  <label className="label-text-sowing">Irrigation type</label>
                  <div className="inputContainer">
                     <select className='custom-select' value={Irrigation} onChange={handleChangeIrrigation}>
                        <option value="" disabled selected>select your option</option>
                        {
                           sowingIrrigation && sowingIrrigation.map((item: any, i: any) => (
                              <option value={item._id} key={i}>{item.irrigation_type_name}</option>
                           ))
                        }
                     </select>
                  </div>
                  {isvalid == "invalid" && Irrigation === "" ? <label className="required-label">* required</label> : "" }
               </div>
               <div className='nest-container-last'>
                  <label className="label-text-sowing">Tillage type</label>
                  <div className="inputContainer">
                     <select className='custom-select' value={Tillage} onChange={handleChangeTillage} >
                        <option value="" disabled selected>select your option</option>
                        {sowingTillage && sowingTillage.map((item: any, i: any) => (
                           <option value={item._id} key={i}>{item.tillage_type_name}</option>
                        ))}
                     </select>
                  </div>
                  {isvalid == "invalid" && Tillage === "" ? <label className="required-label" >* required</label> : "" }
               </div>
            </div>

            <div className='first-last-container'>
               <div className='nest-container-first' >
                  <label className="label-text-sowing">Target Yield</label>
                  <div className="inputContainer">
                     <input type="text" className="input-Field" value={targetyield} placeholder='Target yield t/ha' onChange={OnchangeTargetYield} />
                  </div>
                  {isvalid == "invalid" && targetyield === "" ? <label className="required-label" >* required</label> : "" }
               </div>

               <div className='nest-container-last'>
                  <label className="label-text-sowing">Actual Yield</label>
                  <div className="inputContainer">
                     <input type="text" value={actualyield} className="input-Field" placeholder='Actual yield t/ha' onChange={OnchangeActualYield} />
                  </div>
                  {isvalid == "invalid" && actualyield === "" ? <label className="required-label" >* required</label> : "" }
               </div>
            </div>

            <label className="label-text-sowing">Notes</label>
            <div className="inputContainer">
               <textarea className='text-area-field' value={ notes} onChange={OnchangeNotes} />
            </div>
         </div>
         <hr />
      </>

   );

}

export default Sowingform;