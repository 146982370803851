import Http from "./http";

const SigninGetAll = async (data:any) =>  {

    const response = await Http.post('/users/login',  data).then(
      response =>{
        if(response.status === 200){
          localStorage.setItem('client_id', response.data.UserData[0]._id  );       
          localStorage.setItem("AccessToken",response.data.Token);
          localStorage.setItem("AuthData",  JSON.stringify(response.data));
          window.dispatchEvent(new Event('triggeruserArea'));

          console.log(localStorage.getItem("AuthData"));
          
        }
        return response;
      }
    ).catch(error=>{
      console.log(error);
    });  
   return response;
  
  }

  export const signinservices = {
    SigninGetAll
  }