import { mapConstants } from "../actions/constants/mapConstants"

export const signinReducer = (state = {}, action) => {

    switch (action.type) {

        case mapConstants.SigninGetAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }

        case mapConstants.SigninGetAllSuccess:
            
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                signindata:action.payload
            }
            
        case mapConstants.SigninGetAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }
        default : return state
    }
}