import { sowingConstants } from "./constants/sowingConstants"

const SowingetAll = (data) =>{
    return {    
        type: sowingConstants.SowingbyFieldAllRequest, 
        payload : data
    }
}

const CropId = (data) =>{
    return {    
        type: sowingConstants.CropbyIdAllRequest, 
        payload : data
    }
}

const TillageId = (data) =>{
    return {    
        type: sowingConstants.TillagebyIdAllRequest, 
        payload : data
    }
}

const IrrigationId = (data) =>{
    return {    
        type: sowingConstants.IrrigationbyIdAllRequest, 
        payload : data
    }
}

const SowingGetAll =() =>{
    return{
        type :sowingConstants. SowingGetAllRequest
    }
}

const Croptypemature = (data:any) =>{
    return {
        type : sowingConstants.CroptypeAllRequest,
        payload : data
    }
}

const sowingInsertAll = (data : any) =>{
       
    return {
        type : sowingConstants.SowingAllRequest,
        payload : data
    }
    
}

const cropAll = ()=>{
    return {
        type : sowingConstants.CropAllRequest,
    }
}

const irrigationAll = () =>{
    return {
        type : sowingConstants.IrrigationAllRequest
    }
} 

const tillageAll = () =>{
    return {
        type : sowingConstants.TillageAllRequest
    }
} 

const SowingDelete = (data) =>{
    return {
        type : sowingConstants.SowingDeleteAllRequest,
        payload : data
    }
}

const SowingDateRange = (data) =>{
    return {
        type : sowingConstants.SowingDateAllRequest,
        payload : data
    }
}

export const sowingActions = {
    SowingetAll,CropId,TillageId,IrrigationId,SowingGetAll,irrigationAll,tillageAll,
    cropAll, sowingInsertAll,Croptypemature,SowingDelete,SowingDateRange
}