import { emailServices } from '../Service/emailnotifyServices';
import {put, call } from 'redux-saga/effects';
import { mapConstants } from "../actions/constants/mapConstants";

function* emailnotifysaga(request:any) {
    try {
        const response = yield call(emailServices.emailAll, request.payload);

        if(response.status === 200){
            
            yield put({
                type:mapConstants.EmailGetAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:mapConstants.EmailGetAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {
        yield put({
            type:mapConstants.EmailGetAllFailure,
            payload: error
        });
      }
  }


  function* emailfieldnotifysaga(request:any) {
    try {
        const response = yield call(emailServices.emailfieldAll, request.payload);

        if(response.status === 200){
            
            yield put({
                type:mapConstants.EmailFieldGetAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:mapConstants.EmailFieldGetAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {
        yield put({
            type:mapConstants.EmailFieldGetAllFailure,
            payload: error
        });
      }
  }

  export const emailsaga = {
    emailnotifysaga,emailfieldnotifysaga
  }