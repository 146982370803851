
import {put } from 'redux-saga/effects';
import { mapConstants } from "../actions/constants/mapConstants";

function* mapstate(request:any) {
    try {
        yield put({
            type:mapConstants.MapstateAllSuccess,
            payload : request.payload
        });
            
      } catch (error) {
        yield put({
            type:mapConstants.MapstateAllFailure,
            payload: error
        });
      }
  }

  export const Mapstate = {
    mapstate
  }