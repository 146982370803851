import { useEffect, useState } from "react";
import SignUp from "../../Component/Login/SignUp";
import Signin from "../../Component/Login/Signin";
import { FaUserAlt } from "react-icons/fa";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from 'react-redux';
import { mapActions } from '../../actions/mapActions';
import { IoIosLogOut } from "react-icons/io";


function LoginContainer(){

    const dispatch = useDispatch();
    const [login, setLogin] = useState(true);
    const [signin, setShowsignin] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [signin1, setShowsignin1] = useState(true);
    const [getdata, setData] = useState('');
    const isAuth = useSelector((state:any) => state.signinReducer);
    

    useEffect(()=>{

        if(isAuth.isSuccess){
            setLogin(false);
        }

        if(!isAuth.isSuccess){
            setLogin(true);
        }

    },[isAuth.isSuccess, !isAuth.isSuccess]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    useEffect(()=>{
        const storedUser = JSON.parse(localStorage.getItem('AuthData'));
        const storedToken = localStorage.getItem('AccessToken');

        if (storedToken  !== null) {  
            setLogin(false);
            setData(storedUser.UserData[0].firstName);  
            dispatch(mapActions.userareaAll(storedUser.UserData[0]._id));
        }
    },[isAuth.isSuccess]);

    const logoutAction = () => { 
        localStorage.removeItem('AccessToken');
        localStorage.removeItem('AuthData');
        setLogin(true);
        window.location.reload();
    }

    const toggleSignin = (res: any) => { 
        if (res.status === 200) {
            dispatch(mapActions.signinAll({
                email: res.data.email,
                firstName: res.data.given_name,
                lastName: res.data.family_name,
                passwordHash: "",
                phone: "1234567890",
                isAdmin: false,
                isGoogleAuth: true
            }));
            setData(res.data.given_name);        
            setShowsignin(false); 
            setLogin(false);
        } else {
            setShowsignin1(!signin1);
        }
    };

    return (
        <div className='icon-container'>
        {(login ) ? (
            < FaUserAlt size={'1.3em'} className="user-icon icon-visible" onClick={() => setShowsignin(!signin)} />) : (
            <div>
                <IoIosLogOut size={'1.3em'} className='login-user icon-visible' onClick={toggleDropdown} />
                {
                    (isOpen) && 
                    <ul className='dropdown-menu'>
                        <li>{getdata}</li>
                        <li onClick={logoutAction}>logout</li>
                    </ul>
                }
            </div>
        )}
        {  signin  && (<div className="login-form">
            <div className='close-container'>         
                <AiOutlineClose className='close-icon' size={'2.4em'} onClick={() => setShowsignin(!signin)} />
            </div>
            <div className='login-container'>
                <GoogleOAuthProvider clientId="713022299596-e6mp5rdfqrp3jv2okl9oul7tbkusluh5.apps.googleusercontent.com">
                    {signin1 ? <Signin toggleSignin={toggleSignin} /> : <SignUp toggleSignin={toggleSignin} />}
                </GoogleOAuthProvider>
            </div>
        </div>)}
       </div> 
    );

}

export default LoginContainer;