import { View, Map, Feature } from "ol";
import { useEffect, useRef } from "react";
import OSM from "ol/source/OSM";
import TileLayer from "ol/layer/Tile";
import 'ol/ol.css';
import { useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import CircleStyle from "ol/style/Circle";
import VectorSource from "ol/source/Vector";
import Polygon from "ol/geom/Polygon";
import VectorLayer from "ol/layer/Vector";
import { useNavigate } from "react-router-dom";
import * as Constant from '../../common/constants';

function MapView() {

    const ref = useRef<HTMLDivElement>(null);
    const mapRef = useRef<Map>(null);
    const dispatch = useDispatch();
    

    const style = new Style({
        fill: new Fill({
            color: 'rgba(155, 187, 224, 0.3)'
        }),
        stroke: new Stroke({
            color: 'rgba(243,183,39, 0.9)',
            width: 6,
        }),
        image: new CircleStyle({
            radius: 2,
            fill: new Fill({
                color: '#ffcc33'
            })
        }),
    });

    
    useEffect(() => {
        if (ref.current && !mapRef.current) {
            
            mapRef.current = new Map({
                layers: [new TileLayer({ source: new OSM({
                    url: Constant.MAPURL,               
                }
                ) })],
                view: new View({ center: [84.66750819333932, 55.64067604991081], zoom: 4 }),
                target: ref.current
            });   
        }
    }, [ref, mapRef, dispatch]);

   
    useEffect(() => {
        // navigate('/');
        mapRef.current?.getView().setZoom(6)
        mapRef.current.getView().setMaxZoom(20);
    
        var getcoordinates = sessionStorage.getItem('cod');        
        var intArray = getcoordinates.split(',').map(Number);
        const newArr = [];

        while (intArray.length) {
            newArr.push(intArray.splice(0, 2));    
        }

        const shapefeatures = new Feature({
            geometry: new Polygon([newArr])
        });
        const vectorSource = new VectorSource({ 
            features: [
                shapefeatures
            ],
        });
        const vectorLayer = new VectorLayer({ 
            source: vectorSource,
            style: [style],
        });   
        mapRef.current?.addLayer(vectorLayer); 
        const polygonExtent = shapefeatures.getGeometry().getExtent(); 
        
        mapRef.current?.setView(new View({ 
               center: newArr[0], 
               zoom: parseInt(sessionStorage.getItem('zoom')),
           }
       )); 


       mapRef.current?.getView().fit(polygonExtent, { padding: [100, 100, 100, 100] });
    
    }, [mapRef]);

    return <div className="map-container" ref={ref} >    
    </div>
}

export default MapView;
