import { mapConstants } from "../actions/constants/mapConstants"

export const seasoncreateReducer = (state = {}, action) =>  {

    switch (action.type) {
        case mapConstants.SeasonAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }

        case mapConstants.SeasonAllSuccess :       
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                seasondata : action.payload
            }
            
        case mapConstants.SeasonAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                seasonDataerror : action.payload
            }
        default : return state
    }
}

export const seasongetAllReducer = (state = {}, action) =>  {

    switch (action.type) {
        case mapConstants.SeasonGetallRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }

        case mapConstants.SeasonGetallSuccess :        

            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                seasonalldata : action.payload
            }
            
        case mapConstants.SeasonGetallFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                

            }
        default : return state
    }
}

export const seasondeleteReducer = (state = {}, action) =>  {

    switch (action.type) {
        case mapConstants.SeasonDeleteAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case mapConstants.SeasonDeleteAllSuccess :        
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                seasondelete : action.payload
            }
        case mapConstants.SeasonDeleteAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                seasonDeleteError : action.payload
            }
        default : return state
    }
}