import Http from "./http";
import { instance2 } from "./http";

const seasonCreate = async (data:any) =>  {
   try{
      const response = await instance2.post('/season/create',data);
      return response;
    }
    catch(error){    

      return error.response.data;
    }
  }

  const seasonGetAll = async (data) =>  {
    var field = {field_id:data};
    try{
        const response = await instance2.post('/season/getbyId',field); 
        return response;
    } catch(error) {
      console.log(error);
    }
  }

  const seasondeleteAll = async (data) => {
    try {
      const response = await instance2.delete('/season/'+data);
      return response;
    }
    catch(error) {
      return error.response.data;
    }
  }

  export const seasonservices = {
    seasonCreate,seasonGetAll,seasondeleteAll
  }