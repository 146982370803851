import { cropidentifyConstants } from "../actions/constants/cropIdentifyConstant"

export const cropdiseaseReducer = (state = {}, action) =>  {

   
    switch (action.type) {
        case cropidentifyConstants.DiseasesIdentifyAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false,               
            }

        case cropidentifyConstants.DiseasesIdentifyAllSuccess :     
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                diseasedata : action.payload
            }
            
        case cropidentifyConstants.DiseasesIdentifyAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                tabledata : action.payload
            }
        default : return state
    }

   
    
}


export const cropallReducer = (state = {}, action) =>  {

   
    switch (action.type) {
        case cropidentifyConstants.DiseasestableAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false,               
            }

        case cropidentifyConstants.DiseasestableAllSuccess :     
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                tabledata : action.payload.resultObject
            }
            
        case cropidentifyConstants.DiseasestableAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                
            }
        default : return state
    }

   
    
}

export const growthReducer = (state = {}, action) =>  {

   
    switch (action.type) {
        case cropidentifyConstants.GrowthIdentifyAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false,               
            }

        case cropidentifyConstants.GrowthIdentifyAllSuccess :     
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                diseasedata : action.payload
            }
            
        case cropidentifyConstants.GrowthIdentifyAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                tabledata : action.payload
            }
        default : return state
    }   
}


export const growthallReducer = (state = {}, action) =>  {

   
    switch (action.type) {
        case cropidentifyConstants.GrowthtableAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false,               
            }

        case cropidentifyConstants.GrowthtableAllSuccess :     
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                tabledata : action.payload.resultObject
            }
            
        case cropidentifyConstants.GrowthtableAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                
            }
        default : return state
    }

   
    
}

