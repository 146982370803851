import { cropidentifyConstants } from "./constants/cropIdentifyConstant"



const diseaseIdentifyAll = (data) => {
    return {
        type: cropidentifyConstants.DiseasesIdentifyAllRequest,
        payload: data
    }
}

const diseaseTableAll = () => {
    return {
        type: cropidentifyConstants.DiseasestableAllRequest,
    }
}

const growthIdentifyAll = (data) => {
    return {
        type: cropidentifyConstants.GrowthIdentifyAllRequest,
        payload: data
    }
}

const growthTableAll = () => {
    return {
        type: cropidentifyConstants.GrowthtableAllRequest,
    }
}

export const cropIdentifyActions = {
    diseaseIdentifyAll, diseaseTableAll, growthIdentifyAll, growthTableAll
}