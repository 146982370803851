import { useState, useEffect } from 'react';
import { AiOutlineClose } from "react-icons/ai";
import { FiAlertTriangle } from "react-icons/fi";
import { useDispatch, useSelector } from 'react-redux';
import { mapActions } from '../actions/mapActions';
import { toast } from 'react-toastify';
import * as Constant from '../common/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../Container/spinner';

const SidePanel = ({ }) => {

  const [coordinates, setcoordinates] = useState([[]]);
  const favdata = useSelector((state: any) => state.favoriteReducer);
  const [details, setdetails] = useState({ name: "", coordinates: [], extend: '', userId: "", zoom: "", type: sessionStorage.getItem('shape') })
  const [message, setMessage] = useState('');
  const dispatch = useDispatch();
  const [stateUpdate, setstateUpdate] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = useLocation();
  const data: any = location.state;
  const [isDrawPopup, setDrawPop] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const mapCoord = useSelector((state:any) => state.mapCoordReducer.mapcoord);

  useEffect(()=>{
    if(coordinates){setcoordinates([[]])}
  },[]);

  useEffect(()=>{
    if(mapCoord){
      let array2 = mapCoord.coordinate.toString().split(',');
      const newArr = [];
      while (array2.length) {
        newArr.push(array2.splice(0, 3));
        setcoordinates(newArr);
      }  
    }
  },[mapCoord]);

  useEffect(() => {
    if (!data) {
      navigate('/app');
    }
  }, [!data]);

  useEffect(() => {
    window.addEventListener('beforeunload', (event) => {
      if(location.pathname === '/aoi'){
        console.log("called AOI");        
      }
    });
  }, [state]);

  const formattedText = coordinates.map(row => row.join(' ')).join('\n'); //creating 

  const handleChange = (event) => {
    setMessage(event.target.value);
  };

  const saveCoordinate = async () => {

    if (message.length === 0) {
      toast.warn('Enter the AOI Name', { position: Constant.TOAST_POSITION });
    }

    if (localStorage.getItem('AccessToken') !== null) {
      setIsSubmitting(false);
      setdetails({
        ...details,
        name: message,
        coordinates: (sessionStorage.getItem('shape') === 'rectangle') ? (sessionStorage.getItem('coordinate_rect').split(',').map(Number)) : (sessionStorage.getItem('coordinate_poly').split(',').map(Number)),
        extend: sessionStorage.getItem('extend_'),
        userId: localStorage.getItem('client_id'),
        zoom: sessionStorage.getItem('zoom-value')
      });
      setstateUpdate(!stateUpdate);
    }
    else {
      toast.warn('Please Login to Proceed', { position: Constant.TOAST_POSITION });
    }

  }

  const onChangeCancel = () => {
    setDrawPop(!isDrawPopup);
  }

  useEffect(()=>{
    if(favdata.isError){  
      toast.warn(favdata.favorite, { position: Constant.TOAST_POSITION});
      setIsSubmitting(false);
  }
  favdata.isError = false
  },[favdata.isError]);

  useEffect(() => {
    if (favdata.isSuccess) {
      toast.success(favdata.favoritedata.message, { position: Constant.TOAST_POSITION });
      navigate('/app');
      var userID = localStorage.getItem('client_id');
      dispatch(mapActions.userareaAll(userID));
      setIsSubmitting(false);
      favdata.isSuccess = false;
    }
  }, [favdata.isSuccess]);

  useEffect(() => {
    if (details.name !== "") {
      dispatch(mapActions.favoriteAll(details));
    }
  }, [stateUpdate]);



  return (
    <>
      <div className='side-panel-container'>
        <div className="side-panel" >
          <div className='close-icon' style={{ cursor: 'pointer' }} onClick={onChangeCancel} >
            <div className='label-area'><label>Area of Interest</label></div>
            <AiOutlineClose />
          </div>
          <div className='interest-container' style={{ width: "inherit" }}>
            <label className='label-area-panel'>AOI Name</label>
            <input className="input-area" type='text' placeholder='Enter the AOI name..' id="area" onChange={handleChange} required />
            <label className='label-area-panel'>Coordinates</label>
            <textarea readOnly className="input-area-coordinate" placeholder='co-ordinates..' cols={coordinates[0].length * 1} value={formattedText} rows={coordinates.length} id="coordinates" />
            <div className='sidepanel-tip' style={{ width: '100%', textAlign: 'center', height: '40%', overflow: 'auto', color: '#9fb4c7' }}>
              <h3 className=''>Draw first field</h3>
              <label className='tip-sidepanel'>Find your field on the map, mark borders of your field with dots, and don't forget to close the polygon contour</label>
            </div>
            <div style={{ display: "block", position: "fixed", width: "inherit", bottom: '0' }}>
              <button onClick={saveCoordinate} className='btn-save' id='save-btn' disabled={isSubmitting || !(mapCoord && mapCoord.isState)}>SAVE</button> 
              <button className='btn-cancel' onClick={onChangeCancel}> CANCEL DRAWING </button>
            </div>
          </div>
        </div>
      </div>
      {isDrawPopup && <DrawPopup close={() => setDrawPop(!isDrawPopup)} />}
      {favdata.isLoading && <div className="spinner-wrapper">
          <Spinner />
        </div>}
    </>
  );
};

export default SidePanel;

export function DrawPopup({ close }) {


  const navigate = useNavigate();

  const OnchangeDrawField = () => {
    window.dispatchEvent(new Event('canceldrawing'));
    navigate('/app');
    document.getElementById('myNewId').style.cursor = 'default';
  }

  return (
    <>
      {<div className="login-form">
        <div className="container-draw-popup-aoi">
          <AiOutlineClose size={'1.3em'} className='close-icon' onClick={close} />


          <div className="drawpopContainer">
            <div className="" style={{ width: '100%', textAlign: 'center', marginTop: '30px', borderRadius: '10px', color: 'white' }}>
              <FiAlertTriangle style={{ marginTop: '20px' }} size={'2.7em'} color='#f0b20a' />
              <div style={{ padding: '10px' }}>
                <label>Are you sure you want to leave now?</label>
              </div>
              <div style={{ padding: '10px', color: "#6e7b7b" }}>
                <p>The contours you've drawn will not be saved, if you leave now.</p>
              </div>
              <div style={{ padding: '10px', display: 'flex', }}>
                <button onClick={() => { OnchangeDrawField(); close() }} className="btn-save" style={{ backgroundColor: 'rgb(154 162 169)', cursor: 'pointer', marginRight: "10px" }} >LEAVE NOW</button>
                <button className="btn-save" onClick={() => close()} style={{ backgroundColor: '#4c99e7', cursor: 'pointer' }} >BACK TO DRAWING</button>
              </div>
            </div>

          </div>
        </div>
      </div>}
    </>
  )

}
