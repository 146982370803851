import { instance2 } from "./http";

const sateliteGetAll = async   () =>  {

  const response = await instance2.get('/satellite/getAll').then(
      response =>{
        return response;
      }
    ).catch(error=>{
       console.log(error);
    });

    return response;
}

export const mapServices = {
  sateliteGetAll
}
