import { mapConstants } from "../actions/constants/mapConstants"

export const mapstateReducer = (state = {}, action) =>  {

    switch (action.type) {

        case mapConstants.MapstateAllRequest:
            
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }

        case mapConstants.MapstateAllSuccess :     
           
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                mapstate : action.payload
            }
            
        case mapConstants.MapstateAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }
        default : return state
    }
}

export const mapCoordReducer = (state = {}, action) =>  {

    switch (action.type) {

        case mapConstants.MapCoordinateAllSuccess :     
           
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                mapcoord : action.payload
            }
            
        default : return state
    }
}

export const myfarmReducer = (state = {}, action) =>  {

    switch (action.type) {

        case mapConstants.MyFarmPanelAllSuccess :     
           
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                mapfarm : action.payload
            }
            
        default : return state
    }
}