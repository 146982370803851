import { AiOutlineClose } from "react-icons/ai";
import { IoSearchSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { mapActions } from "../../../actions/mapActions";
import { toast } from "react-toastify";
import * as Constant from "../../../common/constants";

function Seasonform({ close, fieldvalue }) {
  const userAOI = useSelector((state: any) => state.userareaReducer);
  const seasonpost = useSelector((state: any) => state.seasoncreateReducer);
  const dispatch = useDispatch();

  const [state, setStatechange] = useState(true);
  const [createstate, setCreatechange] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    seasonpost.isSuccess = false;
  }, []);

  const [season, setseason] = useState({
    season_name: "",
    season_start: "",
    season_end: "",
    field_id: fieldvalue,
    user_id: localStorage.getItem("client_id"),
  });

  useEffect(() => {
    setCreatechange(!createstate);
  }, [seasonpost.isSuccess]);

  useEffect(() => {
    if (seasonpost.isSuccess) {
      toast.success(seasonpost.seasondata.message, {
        position: Constant.TOAST_POSITION,
      });
      dispatch(mapActions.seasonGetAll(fieldvalue));
      setIsSubmitting(false);
    }
    if (seasonpost.isError) {
      toast.warn(seasonpost.seasonDataerror, {
        position: Constant.TOAST_POSITION,
      });
      setIsSubmitting(false);
    }
  }, [seasonpost.isSuccess, seasonpost.isError]);

  const onChangeSeasonhandle = () => {
    if (season.season_start < season.season_end) {
      if (
        season.season_name &&
        season.season_end &&
        season.season_start &&
        season.field_id &&
        season.user_id
      ) {
        setIsSubmitting(true);
        dispatch(mapActions.seasonAll(season));
        setStatechange(!state);
      } else {
        if (!season.season_name || !season.season_end || !season.season_start) {
          toast.warn("Name and Daterange Required", {
            position: Constant.TOAST_POSITION,
            closeButton: false,
          });
        }
      }
    } else {
      toast.warn(Constant.TOAST_DATE_RANGE, {
        position: Constant.TOAST_POSITION,
        toastId: "success1",
      });
    }
  };

  return (
    <>
      <div className="season">
        <div className="outer-container1" style={{ width: "25%" }}>
          <div className="container1-season-temp">
            <div className="season-field-label">
              <label>Create season</label>
            </div>
            <div className="close-container-season">
              <AiOutlineClose
                style={{ cursor: "pointer" }}
                className="close-icon"
                onClick={close}
              />
            </div>
            <div className="nest-container-first">
              <label className="label-text-season">Season name</label>
              <div className="inputContainer">
                <input
                  type="text"
                  className="inputField"
                  onChange={(e) =>
                    setseason({ ...season, season_name: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="first-last-container">
              <div className="nest-container-first">
                <label className="label-text-season">Start date</label>
                <div className="inputContainer">
                  <input
                    type="date"
                    className="inputField"
                    onChange={(e) =>
                      setseason({ ...season, season_start: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
              <div className="nest-container-last">
                <label className="label-text-season">End Date</label>
                <div className="inputContainer">
                  <input
                    type="date"
                    className="inputField"
                    onChange={(e) =>
                      setseason({ ...season, season_end: e.target.value })
                    }
                    required
                  />
                </div>
              </div>
            </div>
            <div className="nest-container-first">
              <label className="label-text-season">
                Copy the fields from the season
              </label>
              <div
                className="inputContainer"
                style={{ backgroundColor: "white", marginTop: "10px" }}
              >
                <IoSearchSharp style={{ paddingLeft: "15px" }} />
                <input
                  type="text"
                  className="inputField"
                  placeholder="field search"
                  style={{ border: "none" }}
                />
              </div>
            </div>
            <div className="checkbox-container-season">
              {userAOI &&
                userAOI.userareaData &&
                userAOI.userareaData.map((item: any, i: any) => (
                  <div className="checkbox-label-container" key={i}>
                    <input type="checkbox" className="season-input-checkbox" />
                    <label className="label-text-season labelseason-checkbox-container">
                      {item.name}
                    </label>
                    <hr
                      className="seasno-hr"
                      style={{ backgroundColor: "black" }}
                    />
                  </div>
                ))}
            </div>
            <div className="first-last-container">
              <div className="nest-container-first">
                <div className="inputContainer">
                  <input type="submit" value="CANCEL" onClick={close} />
                </div>
              </div>
              <div className="nest-container-last">
                <div className="inputContainer">
                  <input
                    type="submit"
                    value="CREATE SEASON"
                    onClick={onChangeSeasonhandle}
                    disabled={isSubmitting}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Seasonform;
