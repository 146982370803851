import { mapServices } from "../Service/mapServices";
import {put, call } from 'redux-saga/effects';
import { mapConstants } from "../actions/constants/mapConstants";

function* sateliteGetAl() {
    try {
        const response = yield call(mapServices.sateliteGetAll);  
           
        if(response.status === 200){
            yield put({
                type:mapConstants.SateliteGetAllSuccess,
                payload : response.data.resultObject
            });
        }else{
            yield put({
                type:mapConstants.SateliteGetAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {
        yield put({
            type:mapConstants.SateliteGetAllFailure,
            payload: error
        });
      }
  }


  export const mapSaga = {
    sateliteGetAl
  }