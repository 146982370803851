import { instance2 } from "./http";

const irrigationGetAll = async () =>  {  
    const response = await instance2.get('/irrigationtype/getAll').then(
      response =>{
        return response;
      }
    ).catch(error=>{
       console.log(error);
    });
    return response;
  }

const tillageGetId = async (data) =>  {  

  var tillageid = {
    tillage_id: data
  };

    const response = await instance2.post('/tillagetype/getbyId',tillageid).then(
      response =>{
        return response;
      }
    ).catch(error=>{
       console.log(error);
    });
      
    return response;
  }

const tillageGetAll = async () =>  {  
    const response = await instance2.get('/tillagetype/getAll').then(
      response =>{
        return response;
      }
    ).catch(error=>{
       console.log(error);
    });
      
    return response;
  }

  const cropGetId = async (data) =>{
    var cropid = {
      crop_id:data
    };
    const response = await instance2.post('/crop/getbyId',cropid).then(
      response =>{
        return response;
      }
    ).catch(error=>{
       console.log(error);
    });
    return response;
  }

  const cropGetAll = async () =>  {  
    const response = await instance2.get('/crop/getAll').then(
      response =>{
        return response;
      }
    ).catch(error=>{
       console.log(error);
    });
    return response;
  }

  const sowingInsertAll = async (data : any) => 
  {  
    const response = await instance2.post('/sowing/create',data).then(
      response =>{
        return response;
      }
    ).catch(error=>{
      return error.response.data;
    });
    return response;
  }

  const maturingCrop = async (data : any) =>{
    var cropmature = {
      crop_id:data
    };
    const response = await instance2.post('/croptype/getMaturebyid',cropmature).then(
      response =>{
        return response
      }
    ).catch(error=>{
      console.log(error);
    });
    return response;
  }

  const sowingGetIdAll = async (data : any)  =>  {
    var field = {sowing_field_id:data};
    try{
      const response = await instance2.post('/sowing/getbyId',field); 
      return response;
    }
    catch(error){
      console.log(error);
    }
  }

  const irrigationGetIdAll = async (data) =>{

    var irrigation_id = {
      irrigation_id:data}; 
    
    try{        
          const response = await instance2.post('/irrigationtype/getbyId',irrigation_id); 
          return response;
    }
    catch(error){
      console.log(error);
    }
  }

  const sowingGetAll = async () =>  {
    try{        
          const response = await instance2.get('/sowing/getAll');        
          return response;
    }
    catch(error){
      console.log(error);
    }
  }

  const sowingDelete = async (data) => {
    try {
      const response = await instance2.delete(`/sowing/${data}`);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  const sowingDaterange = async (data) =>{
    try{
      const response = await instance2.post('/sowing/validate',data);
      return response;
    }
    catch(error){
      console.log(error);
    }
  }
  
  export const sowingallservices = {
    irrigationGetAll,tillageGetAll,cropGetAll,sowingInsertAll,maturingCrop,sowingGetIdAll,cropGetId,tillageGetId,irrigationGetIdAll,sowingGetAll,sowingDelete,sowingDaterange
  }