import Seasonform from "./Season/Seasonform";
import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import Sowingform from "./Sowingform";
import { MdOutlineKeyboardArrowUp, MdDelete } from "react-icons/md";
import MapView from "./Mapview";
import { useDispatch, useSelector } from "react-redux";
import { mapActions } from "../../actions/mapActions";
import { sowingActions } from "../../actions/sowingActions";
import { fieldActions } from "../../actions/fieldActions";
import { BiPlus } from "react-icons/bi";
import * as Constant from '../../common/constants';
import { toast } from "react-toastify";
import Spinner from "../../Container/spinner";

export function MainForm({ close, fieldata, name, group }) {

  const [state, setState] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [indexvalue, setindexvalue] = useState("");
  const [accordionState, setaccordionState] = useState(false);
  const [sowingState, setsowingState] = useState(false);
  const [inputValue, setInputValue] = useState({});
  const [sowingData, setSowingData] = useState([]);
  const [fieldvalue, setFieldvalue] = useState(name);
  const [groupname, setGroupname] = useState(group);
  const [seasonVal, setSeasonVal] = useState("");
  const [sowingstate, setSowingstate] = useState([]);
  const [stateChange, setStatechange] = useState({ _id: "", index: "" });
  const [stateAdd, setStateAdd] = useState(true);
  const [sowingAcc, setSowingacc] = useState(false);
  const [sowingdelete, setSowingdelete] = useState(false);
  const [sowingdeleteId, setSowingdeleteId] = useState("");
  const [sowingdeleteIndex, setSowingdeleteIndex] = useState();
  const [sowingIndex, setSowingIndex] = useState("");
  const [savingState, setSavingstate] = useState(true);
  const [seasonId, setSeasonId] = useState("");
  const [isdeletePopup, setDeletePopup] = useState(false);
  const [isValidate, setValidated] = useState("valid");
  const [accsowingState, setAccsowingstate] = useState({
    sowing_crop_id: "",
    sowing_crop_type_id: "",
    sowing_irrigation_type_id: "",
    sowing_tillage_type_id: "",
    sowing_field_id: "",
    sowing_user_id: localStorage.getItem("client_id"),
    sowing_season_id: "",
    sowing_harvest_date: "",
    sowing_target_yield: "",
    sowing_actual_yield: "",
    sowing_notes: "",
    sowing_date: "",
    index: "",
  });

  const dispatch = useDispatch();
  const seasongetall = useSelector((state: any) => state.seasongetAllReducer); //getById season
  const season = useSelector((state: any) => state.seasoncreateReducer); //season creating
  const fieldstate = useSelector((state: any) => state.fieldstateReducer);
  const deletepost = useSelector((state: any) => state.seasondeleteReducer);
  var sowingArr = [];
  const sowData = useSelector((state: any) => state.Sowingallreducer);
  const deletesowing = useSelector((state: any) => state.sowingdeleteReducer);

  useEffect(() => {
    if (season.isSuccess) {
      sowingstate.push([]);
      setState(false);
      toast.success(season.sowingfieldid, {
        position: Constant.TOAST_POSITION,
      });
    }
  }, [season.isSuccess]);

  useEffect(() => {
    if (season.isError) {
      toast.warn(season.sowingfieldid, {
        position: Constant.TOAST_POSITION,
        closeButton: false,
      });
    }
  }, [season.isError]);

  useEffect(() => {
    dispatch(mapActions.seasonGetAll(fieldata));
    dispatch(fieldActions.FieldState(fieldata));
    dispatch(sowingActions.SowingGetAll());
    dispatch(sowingActions.irrigationAll());
    dispatch(sowingActions.tillageAll());
    dispatch(sowingActions.cropAll());
  }, []);

  useEffect(() => {
    if (fieldstate.isSuccess) {
      setSowingData(fieldstate.fieldstate);
    }
  }, [fieldstate.isSuccess]);

  useEffect(() => {
    if (deletepost.isSuccess) {
      dispatch(mapActions.seasonGetAll(fieldata));
      dispatch(fieldActions.FieldState(fieldata));
    }
  }, [deletepost.isSuccess]);

  useEffect(() => {
    if (sowingData.length > 0) {
      if (seasongetall.isSuccess) {
        var sowingObj = [];
        for (let j = 0; j < seasongetall.seasonalldata.length; j++) {
          for (let i = 0; i < sowingData.length; i++) {
            if (seasongetall.seasonalldata[j]._id === sowingData[i].sowing_season_id ) {
              sowingObj.push(sowingData[i]);
            }
          }
          sowingArr.push(sowingObj);
          sowingObj = [];
        }
        setSowingstate(sowingArr);
      }
    } else {
      if (sowingData.length === 0) {
        if (seasongetall.isSuccess) {
          for (let i = 0; i < seasongetall.seasonalldata.length; i++) {
            sowingstate.push([]);
          }
        }
      }
    }
  }, [sowingData]);

  function checkDateRanges(data) {
    const conflicts = [];

    data.forEach((indexArray, index) => {
      const dateRanges = indexArray.map((entry) => ({
        start: new Date(entry.sowing_date),
        end: new Date(entry.sowing_harvest_date),
      }));

      dateRanges.forEach((range, i) => {
        if (range.start > range.end) {
          conflicts.push({
            index,
            entry: indexArray[i],
          });
        }
      });

      for (let i = 0; i < dateRanges.length; i++) {
        for (let j = i + 1; j < dateRanges.length; j++) {
          if (
            dateRanges[i].start <= dateRanges[j].end &&
            dateRanges[i].end >= dateRanges[j].start
          ) {
            conflicts.push({
              index,
              entry1: indexArray[i],
              entry2: indexArray[j],
            });
          }
        }
      }
    });

    return conflicts;
  }

  const expand = (index: any, item: any) => {
    setaccordionState(!accordionState);
    setActiveIndex(index === activeIndex ? -1 : index);
    setindexvalue(index);
    setsowingState(!sowingState);
    setSowingacc(!sowingAcc);
  };

  const closeContainer = () => {
    setState(!state);
  }

  const ExpansionExtend = (index, item) => {
    setSeasonVal(item._id);
  }

  useEffect(() => {
    dispatch(sowingActions.SowingetAll(fieldata));
    if (deletesowing.isSuccess) {
      toast.success(deletesowing.sowingDelete.message, {
        position: Constant.TOAST_POSITION,
      });
    }
    deletesowing.isSuccess = false;
  }, [deletesowing.isSuccess]);

  useEffect(() => {
    if (deletesowing.isError) {
      toast.success(deletesowing.sowingDelete.message, {
        position: Constant.TOAST_POSITION,
      });
    }
    deletesowing.isError = false;
  }, [deletesowing.isError]);

  const OnchangeseasonHandle = () => {
    setState(true);
    dispatch(mapActions.userareaAll(localStorage.getItem("client_id")));
  };

  const OnclicksaveSowing = () => {
    
    var seasonVal = [];
    const conflicts = checkDateRanges(sowingstate);
    if (conflicts.length > 0) {
      conflicts.forEach((conflict) => {
        seasonVal.push(seasongetall.seasonalldata[conflict.index].season_name);
      });
      const uniqueIndexes = Array.from(new Set(seasonVal));
      toast.warn(`Invalid date range found: ${uniqueIndexes.join(", ")}`, {
        position: Constant.TOAST_POSITION,
        closeButton: false,
      });
    } else {
      setSavingstate(!savingState);

      const hasEmptyFields = [].concat(...sowingstate).
      some(({ sowing_crop_id, sowing_irrigation_type_id, sowing_tillage_type_id,sowing_crop_type_id, 
               sowing_harvest_date,sowing_date, sowing_target_yield, sowing_actual_yield }) => 
        sowing_crop_id === "" || 
        sowing_irrigation_type_id === "" || 
        sowing_tillage_type_id === "" || 
        sowing_crop_type_id === "" || sowing_harvest_date === "" || 
        sowing_date === "" || sowing_target_yield === "" ||
        sowing_actual_yield === "" 
      );

      if (hasEmptyFields) {
        setValidated("invalid");
      } else {
        const values = [].concat(...sowingstate).map(({ index, ...rest }) => rest);
        setValidated("valid");
        dispatch(sowingActions.sowingInsertAll(values));
      }

    }
  }

  const Onfieldvaluechange = (event) => {
    setFieldvalue(event.target.value);
  }

  const Ongroupvaluechange = (event) => {
    setGroupname(event.target.value);
  }

  const handleInputValue = (value) => {
    setInputValue(value);
  }

  const OnchangeDeleteSeason = (item, index) => {
    setDeletePopup(!isdeletePopup);
    setSeasonId(item._id);
  }

  useEffect(() => {
    setStateAdd(true);
  }, [stateAdd]);

  const OnchangereturnSowing = () => {
    setSowingIndex(sowingstate[indexvalue].length);
    sowingstate[indexvalue].splice([sowingstate[indexvalue].length], 0, {
      ...accsowingState,
      index: sowingstate[indexvalue].length,
    });
    setStateAdd(!stateAdd);
  }

  useEffect(() => {

    if (stateChange._id !== undefined) {
      for (let i = 0; i < sowingstate.length; i++) {
        const found = sowingstate[i].findIndex((item:any) => item._id === stateChange._id);
        if (found !== undefined && found !== -1) {
          sowingstate[indexvalue][found] = stateChange;
        }
      }
    } else {
      
      for (let i = 0; i < sowingstate.length; i++) {
        const found = (sowingstate[i]).findIndex((item :any) => item.index === stateChange.index);
        if (found !== undefined && found !== -1) {
            sowingstate[indexvalue][found] = stateChange;
        }
      }
      
    }
  }, [stateChange]);

  const Deletesowing = (item, index) => {
    setSowingdelete(!sowingdelete);
    if (item._id === undefined) {
      setSowingdeleteId("undefined");
    } else {
      setSowingdeleteId(item._id);
    }
    setSowingdeleteIndex(index);
  };

  const deleteSowingform = () => {
    if (sowingdeleteId === "undefined") {
      sowingstate[indexvalue].splice(sowingdeleteIndex, 1);
  } else {
      const sowingdeleteIndex = sowingstate[indexvalue].findIndex((item) => item._id === sowingdeleteId);
      if (sowingdeleteIndex !== -1) {
        sowingstate[indexvalue].splice(sowingdeleteIndex, 1);
        dispatch(sowingActions.SowingDelete(sowingdeleteId));
      }
      
    }
    setSowingstate([...sowingstate]);
  }

  const Onchangeaccordion = (data) => {
    setStatechange(data);
  }

  const Onchangeokresponse = () => {
    setSowingdelete(!sowingdelete);
    deleteSowingform();
  }

  return (
    <>
      <div className="overview-container">
        <div className="main-container">
          <div className="edit-field-label">
            <label>Edit Field</label>
            <AiOutlineClose style={{ float: "right" }} onClick={() => close()}  />
          </div>
          <div className="main-section-sowing-container">
            <div className="main-section-container">
              <div className="map-section-container">
                <MapView />
              </div>
              <div className="nest-container-last">
                <div className="field-container">
                  <input  className="field" type="text"  placeholder="Field Name" value={fieldvalue} onChange={Onfieldvaluechange} disabled/>
                  <input  className="field" type="text" placeholder="Group Name" value={groupname} onChange={Ongroupvaluechange} disabled />
                </div>
                <div className="crop-rotation-label">
                  <label style={{ color: "white" }}>Crop rotation</label>
                  <div className="Add-season-container">
                    <button className="Add-season-button" onClick={OnchangeseasonHandle}> Add to season </button>
                  </div>
                 { (seasongetall.isLoading) ? 
                    <div className="spinner-wrapper background-season">
                      <Spinner />
                    </div>  : (seasongetall.isError) ? 
                    ( <div className="spinner-wrapper background-season">
                       {Constant.ERROR_MESSAGE_IDENTIFY}
                    </div>) :  ( (seasongetall.seasonalldata.length === 0) ?  
                    ( <div className="spinner-wrapper background-season">
                      <label> 
                       {Constant.MESSAGE_NO_SEASON} <span style={{ color: '#7e7979', fontWeight: 'bold', cursor: 'pointer' }}>Add to Season</span>
                     </label>
                    </div>) :
                     (<div className="season-scroll-bar-expansion">
                        { ((season.isSuccess === true ? !season.isSuccess : season.isSuccess) || (seasongetall.isSuccess && (
                            <div className="season-expansion-panel-container">
                              { seasongetall.seasonalldata &&  (seasongetall.seasonalldata.map(
                                  (item: any, index: any) => {
                                    return (
                                      <div  className="season-expansion-panel" key={index}>
                                        <div className="season-panel" onClick={() =>ExpansionExtend(index, item)}key={item}>
                                          {index === activeIndex ? (
                                            <MdOutlineKeyboardArrowUp className="keyboard-expansion-panel" size={"1.8em"} onClick={() => expand(index, item)}/>
                                          ) : (
                                            <MdOutlineKeyboardArrowDown className="keyboard-expansion-panel" size={"1.8em"} onClick={() => expand(index, item)}/>
                                          )}
                                          <label className="season-label">{item.season_name}</label>
                                          <MdDelete aria-disabled style={{ cursor: "pointer" }} size={"1.6em"} color={"white"} data-tip data-for="registerTip"
                                            onClick={() => OnchangeDeleteSeason(item, index)}  data-tooltip-content="polygon"/>
                                        </div>
                                        <div  className="sowing-expansion-panel" key={index}>
                                          {index === activeIndex && (
                                            <div>
                                              <div className="addveg-expansion-panel-label">
                                                <BiPlus style={{ color: "rgb(53, 146, 253)",}}  size={"1.1em"}/>
                                                <label className="addveg-label" onClick={OnchangereturnSowing}> Add vegetation cycle</label>                                          
                                              </div>
                                              <hr />
                                              { isValidate && stateChange && sowingstate[indexvalue] !== undefined && sowingstate[indexvalue].map(
                                                  (item2: any, index: any) => {
                                                    return (
                                                      <>
                                                        <div className="delete-icon-container">
                                                          <MdDelete 
                                                            onClick={() => Deletesowing(item2,index)}
                                                            style={{ marginRight: "20px", marginTop: "10px",color: "white",cursor: "pointer"}}
                                                            size={"1.1em"}
                                                          />
                                                        </div>
                                                        <Sowingform 
                                                          key={index} 
                                                          onInputValueChange={handleInputValue}
                                                          fieldvalue={fieldata} 
                                                          season={seasonVal} 
                                                          sowingstate={item2}
                                                          Callbackdata={(event) => Onchangeaccordion(event)} 
                                                          isvalid={isValidate} 
                                                          deleted={isValidate}
                                                        />
                                                      </>
                                                    );
                                                  }
                                                ) }
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }
                                )) }
                            </div>) 
                          )) }
                      </div>)) }
                </div>
              </div>
            </div>
          </div>

          <div className="button-container">
            <div className="button-box-delete"></div>
            <div className="button-box-cancel">
              <button className="cancel-button" onClick={close}> CANCEL</button>
            </div>
            <div className="button-box-save">
              <button id="save-button"  className="save-button" onClick={() => { OnclicksaveSowing(); }}>
                SAVE
              </button>
            </div>
          </div>
        </div>
        {state && <Seasonform close={closeContainer} fieldvalue={fieldata} />}
        {sowingdelete && (
          <Popup close={() => setSowingdelete(!sowingdelete)} closetrue={Onchangeokresponse}/>
        )}
        {isdeletePopup && (
          <Popup1 close={() => setDeletePopup(!isdeletePopup)} seasonId={seasonId} />
        )}
      </div>
    </>
  );
}

export function Popup1({ close, seasonId }) {
  const dispatch = useDispatch();

  const Onclickokresponse = () => {
    dispatch(mapActions.SeasondeleteAll(seasonId));
  };

  return (
    <>
      {
        <div className="login-form">
          <div style={{backgroundColor: "rgb(255, 252, 252)",textAlign: "left", padding: "30px",borderRadius: "10px",}}>
            <h2 style={{ color: "black" }}></h2>
            <label className="label-text-delete-sowing">
              Are you sure want to delete ?
            </label>
            <div style={{display: "flex",justifyContent: "right",marginTop: "20px",}}>
              <button className="delete-button" style={{ marginRight: "10px" }} onClick={close}>
                cancel
              </button>
              <button className="delete-button" onClick={() => { Onclickokresponse();close();}} >
                ok
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
}

export function Popup({ close, closetrue }) {
  return (
    <>
      {
        <div className="login-form">
          <div style={{ backgroundColor: "rgb(255, 252, 252)", textAlign: "left",  padding: "30px",borderRadius: "10px",}}>
            <h2 style={{ color: "black" }}></h2>
            <label className="label-text-delete-sowing">
              Are you sure want to delete ?
            </label>
            <div style={{ display: "flex",justifyContent: "right",marginTop: "20px",}}>
              <button className="delete-button" style={{ marginRight: "10px" }} onClick={close}>
                cancel
              </button>
              <button className="delete-button" onClick={closetrue}>
                ok
              </button>
            </div>
          </div>
        </div>
      }
    </>
  );
}
