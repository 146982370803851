import { mapConstants } from "../actions/constants/mapConstants"

export const userareaReducer = (state = {}, action) => {

    switch (action.type) {
        case mapConstants.userareaGetAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case mapConstants.userareaGetAllSuccess:   
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                userareaData : action.payload
            }
        case mapConstants.userareaGetAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }

        default : return state
    }
}

export const favoriteReducer = (state = {}, action) =>  {
    

    switch (action.type) {
        case mapConstants.FavoriteGetAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }

        case mapConstants.FavoriteGetAllSuccess :       
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                favoritedata : action.payload
            }
            
        case mapConstants.FavoriteGetAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                favorite : action.payload
            }
        default : return state
    }
}