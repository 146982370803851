import { signinservices } from "../Service/signinServices";
import {put, call } from 'redux-saga/effects';
import { mapConstants } from "../actions/constants/mapConstants";

function* signinSaga(request:any) {
    try {
        const response = yield call(signinservices.SigninGetAll, request.payload);

        if(response.status === 200){
            yield put({
                type:mapConstants.SigninGetAllSuccess,
                payload : response.data

            });
        }else{
            yield put({
                type:mapConstants.SigninGetAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {
        yield put({
            type:mapConstants.SigninGetAllFailure,
            payload: error
        });
      }
  }


  export const signinsaga = {
    signinSaga
  }