export const fieldConstants = {
    FieldAllRequest : "FIELD_GETALL_REQUEST",
  FieldAllSuccess : "FIELD_GETALL_SUCCESS",
  FieldAllFailure : "FIELD_GETALL_FAILURE",


  FieldgroupAllRequest : "FIELDGROUP_GETALL_REQUEST",
  FieldgroupAllSuccess : "FIELDGROUP_GETALL_SUCCESS",
  FieldgroupAllFailure : "FIELDGROUP_GETALL_FAILURE",

  FieldgroupgetAllRequest : "FIELDGROUPALL_GETALL_REQUEST",
  FieldgroupgetAllSuccess : "FIELDGROUPALL_GETALL_SUCCESS",
  FieldgroupgetAllFailure : "FIELDGROUPAll_GETALL_FAILURE",
  
  FieldnamegetAllRequest : "FIELDNAME_GETALL_REQUEST",
  FieldnamegetAllSuccess : "FIELDNAME_GETALL_SUCCESS",
  FieldnamegetAllFailure : "FIELDNAME_GETALL_FAILURE",

  LandVoidgetAllRequest : "LANDVOID_GETALL_REQUEST",
  LandVoidgetAllSuccess : "LANDVOID_GETALL_SUCCESS",
  LandVoidgetAllFailure : "LANDVOID_GETALL_FAILURE",

  FieldStateAllRequest : "FIELDSTATE_GETALL_REQUEST",
  FieldStateAllSuccess : "FIELDSTATE_GETALL_SUCCESS",
  FieldStateAllFailure : "FIELDSTATE_GETALL_FAILURE",

  FieldDeleteAllRequest : "FIELDDELETE_GETALL_REQUEST",
  FieldDeleteAllSuccess : "FIELDDELETE_GETALL_SUCCESS",
  FieldDeleteAllFailure : "FIELDDELETE_GETALL_FAILURE"

}