
import http from './http';
import { instance2 } from './http';
import { instance1 } from './http';


const FieldService = (data) =>{
    
    const response = instance2.post('/fields/create',data).then(
        response  =>{
          return response;
        }
    ).catch(error=>{
       
        return error;
     });
     return response;
}

const Fieldgroup = (data) =>{

    const response = instance2.post('/fieldgroups/create',data).then(
        response  =>{
          return response;
        }
    ).catch(error=>{
        console.log(error);
     });
     return response;
}

const Fieldvoid = (data) =>{

    const response = instance1.post('/v1/statistics',data).then(
        response  =>{
          return response;
        }
    ).catch(error=>{
        console.log(error);
     });
     return response;
}

const FieldgroupgetAll = () =>{
    const response = instance2.get('/fieldgroups/getAll').then(
        response  =>{
          return response;
        }
    ).catch(error=>{
        console.log(error);
     });
     return response;
}

const Fieldname = (data) =>{

    var fieldId = {
        value : data
    };

    const response = instance2.post('/fields/getAll', fieldId).then(
        response  =>{
          return response;
        }
    ).catch(error=>{
        console.log(error);
});
     return response;
}

const FieldState = (data) =>{


    var fieldId = {
        value : data
    }; 

    const response = instance2.post('/fields/getbyFieldId',fieldId).then(
    response  =>{
        return response;
    }
    ).catch(error=>{
        console.log(error);
    });

    

     return response;
}

const FieldDelete = (data : any) =>{
   const response = instance2.delete('/fields/'+data).then(response=>{
        return response
    }).catch(error=>{
        return error.response.data
    })

    return response;
}

export const fieldService = {
    FieldService,Fieldgroup,FieldgroupgetAll,Fieldvoid,Fieldname,FieldState,FieldDelete
}