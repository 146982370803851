import GeoJSON from "ol/format/GeoJSON";
import Draw from "ol/interaction/Draw";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import CircleStyle from "ol/style/Circle";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import Style from "ol/style/Style";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import * as Constant from '../../common/constants';
import { useDispatch } from "react-redux";
import { mapActions } from "../../actions/mapActions";

function Drawfarming({ map }) {

    var drawInteractionRef = useRef(null);
    const [statechange, setstatechange] = useState(false);
    const dispatch = useDispatch()
    const location = useLocation();

    const baseVector = new VectorLayer({
        source: new VectorSource({
            format: new GeoJSON(),
        }),
        style: [
            new Style({
                fill: new Fill({
                    color: 'rgba(155, 187, 224, 0.3)'
                }),
                stroke: new Stroke({
                    color: 'rgba(255,255,255, 0.9)',
                    width: 6,
                }),
                image: new CircleStyle({
                    radius: 2,
                    fill: new Fill({
                        color: '#ffcc33'
                    })
                }),
            })
        ],
    });

    useEffect(() => {

        if(location.pathname.includes('/myFarm/draw'))
        {
            const myElement = document.querySelector('.map-container');
            myElement.id = 'myNewId';
            document.getElementById('myNewId').style.cursor = 'crosshair';
            
            DrawingField();

            if(map !== null){
                map.removeLayer(baseVector);
                map.addInteraction(drawInteractionRef.current);
            }
        }
        else
        {
            if(!(location.pathname.includes('/draw'))){
                if(drawInteractionRef.current){
              
                    map.removeInteraction(drawInteractionRef.current);
                   const myElement = document.querySelector('.map-container');
                   myElement.id = 'myNewId';
                   document.getElementById('myNewId').style.cursor = 'default';
                   map.removeLayer(baseVector);  
                } 
            }

            
        }

        window.addEventListener('cancel', () => {
            if (map !== null) {
                map.removeLayer(baseVector);
                map.removeInteraction(drawInteractionRef.current);
            }
        });

        return () => {
            window.removeEventListener('addpolygonfarms', () => { });
            window.removeEventListener('cancel', () => { });
        }
    }, [map, location]);


    const DrawingField = () => {

       RemoveInteraction();

       drawInteractionRef.current = new Draw({
            type: 'Polygon',
            source: baseVector.getSource(),
            geometryFunction: null,
            style: [
                new Style({
                    stroke: new Stroke({
                        color: 'rgba(255, 191, 35, 0.5)',
                        width: 1.5
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 191, 35, 0.5)'
                    }),
                    image: new CircleStyle({
                        radius: 6,
                        fill: new Fill({
                            color: 'rgba(255, 191, 35, 0.5)',
                        }),
                    }),
                })
            ],

        });

        drawInteractionRef.current.on('drawend', function (evt) {

            if (map !== null) {

                baseVector.getSource().clear();
                map.addLayer(baseVector);
                let output: any; var featureData = evt.feature; let isStatedisable = "coordinate";
                var changeSavebtn = document.getElementById('btn-save-field') as HTMLButtonElement;
                document.getElementById('myNewId').style.cursor = 'default';

                if (featureData.getGeometry().getArea() > 10000) {
                    output = Math.round((featureData.getGeometry().getArea() / 1000000) * 100) / 100;
                } else {
                    output = Math.round(featureData.getGeometry().getArea() * 100) / 100;
                }

                if (output <= 10000) {
                    changeSavebtn.style.backgroundColor = '#4c99e7';
                    changeSavebtn.style.cursor = 'pointer';
                    map.removeInteraction(drawInteractionRef.current);
                    setstatechange(!statechange);
                    sessionStorage.setItem("coord-poly", featureData.getGeometry().getCoordinates());
                    sessionStorage.setItem('extend_', featureData.geometryChangeKey_.target.extent_);
                    FindlandVoidorNot(featureData.getGeometry().getCoordinates());
                } else {
                    map.removeInteraction(drawInteractionRef.current);
                    isStatedisable = "not coordinate";
                    toast.warn('You selected ' + output + 'ha area. Please reduce area size up to 10000 ha', { position: Constant.TOAST_POSITION })
                }

                dispatch(mapActions.Myfarmpanel({isState: "coordinate"}));
            }
        });
    }

    const FindlandVoidorNot = (coords) => {

        const Data = {
            "input": {
                "bounds": {
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": coords
                    },
                    "properties": {
                        "crs": "http://www.opengis.net/def/crs/EPSG/0/3857"
                    }
                },
                "data": [
                    {
                        "dataFilter": {},
                        "type": "sentinel-2-l2a"
                    }
                ]
            },
            "aggregation": {
                "timeRange": {
                    "from": "2024-01-09T00:00:00Z",
                    "to": "2024-01-29T23:59:59Z"
                },
                "aggregationInterval": {
                    "of": "P10D"
                },
                "width": null,
                "height": null,
                "evalscript": "//VERSION=3\nfunction setup(){\n\treturn {\n\t  input :[\n\t   {\n\t     bands : [\n\t\t   \"B04\",\"B08\",\"dataMask\"\n\t\t ]\n\t   }\n\t  ],\n\t  output : [\n\t   {\n\t     id: \"data\",\n\t\t   bands : 1\n\t   },\n\t   {\n\t    id : \"dataMask\",\n\t\t  bands :1\n\t   }\n\t  ]\n\t};\n}\nfunction evaluatePixel(samples){\n  let ndvi = (samples.B08 - samples.B04)/(samples.B08 + samples.B04);\n  return{\n   data :[ndvi],\n   dataMask : [samples.dataMask],\n  };\n}"
            },
            "calculations": {
                "index": {}
            }
        }

        // dispatch(fieldActions.FieldLandVoid(Data))
    }

    function RemoveInteraction()
    {
        if(map!== null){
            map.removeInteraction(drawInteractionRef.current);
        } 
        baseVector.getSource().clear();
    } 

    return null;

}

export default Drawfarming;