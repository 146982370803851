import { seasonservices } from '../Service/seasonServices';
import {put, call } from 'redux-saga/effects';
import { mapConstants } from "../actions/constants/mapConstants";

function* seasonSaga(request:any) {
    try {
        const response = yield call(seasonservices.seasonCreate, request.payload);
    
        if(response.status === 201){
            yield put({
                type:mapConstants.SeasonAllSuccess,
                payload : response.data

            });
        }else{
            yield put({
                type:mapConstants.SeasonAllFailure,
                payload: response.errorMessage
            });
        }      
      } catch (error) {
    
        yield put({
            type:mapConstants.SeasonAllFailure,
            payload: error
        });
      }
}

function* seasonSagaGetall(request:any) {
try {
    const response = yield call(seasonservices.seasonGetAll,request.payload);

    if(response.status === 200){
        yield put({
            type:mapConstants.SeasonGetallSuccess,
            payload : response.data

        });
    }else{
        yield put({
            type:mapConstants.SeasonGetallFailure,
            payload: response
        });
    }      
    } catch (error) {
    
    yield put({
        type:mapConstants.SeasonGetallFailure,
        payload: error
    });
    }
}

function* seasondeleteSaga(request:any){
try {
    const response = yield call(seasonservices.seasondeleteAll,request.payload);

    if(response.status === 200){
        yield put({
            type:mapConstants.SeasonDeleteAllSuccess,
            payload : response.data

        });
    }else{
        yield put({
            type:mapConstants.SeasonDeleteAllFailure,
            payload: response.status
        });
    }      
    } catch (error) {
    
    yield put({
        type:mapConstants.SeasonDeleteAllFailure,
        payload: error
    });
    }
}

  export const seasonsaga = {
    seasonSaga,seasonSagaGetall,seasondeleteSaga
  }