
import { instance2 } from "./http";

const diseasesAll = async (data:any) =>  {

    try{
          const response = await instance2.post('/cropAnalyse/predict',  data);
          return response;
    }
    catch(error){
      return error.response.data;
    }
  }

  const diseasestableAll = async () =>  {
    
      try{
            const response = await instance2.get('/diseaseIdentify/getAll');
            return response;
      }
      catch(error){
        console.log(error);
      }
    }


    const growthAll = async (data:any) =>  {
        try{
              const response = await instance2.post('/cropAnalyse/predict',  data);
              return response;
        }
        catch(error){
          return error.response.data;
        }
      }
    
      const growthtableAll = async () =>  {     
          try{
                const response = await instance2.get('/cropgrowthprediction/getAll');
                
                return response;
          }
          catch(error){
            console.log(error);
          }
       }

  export const cropIdentifyservices = {
    diseasesAll,diseasestableAll, growthAll, growthtableAll
  }