import { useState, useEffect } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { fieldActions } from "../../actions/fieldActions";
import { useDispatch, useSelector } from "react-redux";
import { FieldList } from "./FieldList";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { FiAlertTriangle } from "react-icons/fi";
import * as Constant from "../../common/constants";
import Spinner from "../../Container/spinner";

export function GroupPopup({ close }) {
  const group = useSelector((state: any) => state.fieldgroupReducer);
  const [groupdata, setgroupdata] = useState({
    group_name: "",
    group_desc: "",
    user_id: localStorage.getItem("client_id"),
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fieldActions.FieldgroupgetAll());
  }, [group.isLoading]);

  const OnclicksaveFieldgroup = async () => {
    dispatch(fieldActions.FieldgroupAll(groupdata));
  };

  return (
    <>
      {
        <div className="login-form">
          <div className="container-group-form">
            <div className="close-container-season">
              <AiOutlineClose
                className="close-icon"
                style={{ color: "black" }}
                onClick={close}
              />
            </div>
            <h2 style={{ color: "black" }}>Group Form</h2>
            <label className="label-text-group">Group name</label>
            <div className="inputContainer">
              <input
                type="text"
                className="inputField"
                required
                onChange={(e) =>
                  setgroupdata({ ...groupdata, group_name: e.target.value })
                }
              />
            </div>
            <div className="forgot-container">
              <label className="label-text-group">Description</label>
            </div>
            <div className="inputContainer">
              <textarea
                className="text-area-field"
                required
                onChange={(e) =>
                  setgroupdata({ ...groupdata, group_desc: e.target.value })
                }
              />
            </div>
            <input type="submit" value="SAVE" onClick={OnclicksaveFieldgroup} />
          </div>
        </div>
      }
    </>
  );
}

export function FarmPanel({}) {
  const fieldgroup = useSelector((state: any) => state.fieldgroupAllReducer);
  const voidland = useSelector((state: any) => state.fieldLandReducer.voidData);
  const fieldreducer = useSelector((state: any) => state.fieldReducer);
  const group = useSelector((state: any) => state.fieldgroupReducer);
  const farmstate = useSelector((state: any) => state.myfarmReducer.mapfarm);

  const [statePopup, setStatePopup] = useState(false);
  const [LandData, setLandData] = useState("");
  const [fieldname, setFieldname] = useState("");
  const [defaultId, setDefaultId] = useState("");
  const [isCancelPopup, setCancelPop] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [fielddata, setFieldData] = useState({
    field_name: "",
    field_coordinates: [],
    field_extend: "",
    field_zoom: "",
    user_id: "",
    group_id: "",
  });

  useEffect(() => {
    dispatch(fieldActions.FieldgroupgetAll());
  }, []);

  useEffect(() => {
    if (voidland <= 0.1) {
      setLandData("Land is Void");
    } else if (voidland >= 0.2 && voidland <= 0.3) {
      setLandData("Land is covered with shrubs and meadows");
    } else if (voidland >= 0.4 && voidland <= 0.5) {
      setLandData("Land is Greenary");
    }
  }, [voidland]);

  useEffect(() => {
    if (fieldname !== null && fieldname !== "") {
      dispatch(fieldActions.FieldsaveAll(fielddata));
    }
  }, [fielddata]);

  useEffect(()=>{
    if(fieldreducer.isSuccess){
      navigate('/app/listAll')
    }
  },[fieldreducer.isSuccess])

  const saveFieldname = async () => {
    if (fieldname.length !== 0) {
      setFieldData({
        ...fielddata,
        field_name: fieldname,
        field_coordinates: sessionStorage.getItem("coord-poly").split(",").map(Number),
        field_extend: sessionStorage.getItem("extend_"),
        field_zoom: sessionStorage.getItem("zoom-value"),
        user_id: localStorage.getItem("client_id"),
        group_id: defaultId,
      });
    } else {
      toast.warn("Enter the Field name", { position: Constant.TOAST_POSITION });
    }
  };

  const closeContainer = () => {
    setStatePopup(!statePopup);
  };

  const canceldrawing = () => {
    setCancelPop(!isCancelPopup);
  };

  const handlechangegroup = async (event) => {
    setDefaultId(event.target.value);
  };

  useEffect(() => {
    if (group.isSuccess) {
      setStatePopup(false);
      setDefaultId(group.fieldgroup.fieldgroup._id);
    }
  }, [group.isSuccess]);

  useEffect(() => {
    if (fieldgroup.isSuccess) {
      setDefaultId(fieldgroup.fieldgroupall[0]._id);
    }
  }, [fieldgroup.isSuccess]);

  useEffect(() => {
    if (fieldreducer.isError) {
      toast.warn(fieldreducer.fieldDataerror, {
        position: Constant.TOAST_POSITION,
      });
    }
  }, [fieldreducer.isError]);

  return (
    <>
      <div className="side-panel">
        <div
          onClick={canceldrawing}
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <AiOutlineClose style={{ cursor: "pointer" }} />
        </div>
        {(
          <div className="interest-container" style={{ width: "inherit" }}>
            <label className="label-area-panel">Field Name</label>
            <input
              className="input-area"
              type="text"
              placeholder="Enter the field name.."
              id="area"
              onChange={(e) => setFieldname(e.target.value)}
            />
            <label className="label-area-panel">Group Name</label>
            <div className="select-custom">
              {fieldgroup.isSuccess && (
                <select value={defaultId} onChange={handlechangegroup}>
                  {fieldgroup.fieldgroupall &&
                    fieldgroup.fieldgroupall.map((item: any, i: any) => (
                      <option value={item._id} key={i}>
                        {item.group_name}
                      </option>
                    ))}
                </select>
              )}
              <label>{LandData}</label>
              <button className="btn-add" onClick={closeContainer}>
                +
              </button>
            </div>
            <div
              className="sidepanel-tip"
              style={{
                width: "100%",
                textAlign: "center",
                height: "40%",
                overflow: "auto",
                color: "#9fb4c7",
              }}
            >
              <h3 className="">Draw first field</h3>
              <label className="tip-sidepanel">
                Find your field on the map, mark borders of your field with
                dots, and don't forget to close the polygon contour
              </label>
            </div>
            <div
              style={{
                display: "block",
                position: "fixed",
                width: "inherit",
                bottom: "0",
              }}
            >
              <button
                className="btn-save"
                id="btn-save-field"
                onClick={saveFieldname}
                disabled={ farmstate && farmstate.isState === "coordinate" ? false : true}
              >
                SAVE
              </button>
              <button className="btn-cancel" onClick={canceldrawing}>
                CANCEL DRAWING
              </button>
            </div>
          </div>
        )}
        {statePopup && <GroupPopup close={() => setStatePopup(!statePopup)} />}
        {isCancelPopup && (
          <DrawPopup close={() => setCancelPop(!isCancelPopup)} />
        )}
        
      </div>
      {fieldreducer.isLoading && <div className="spinner-wrapper">
          <Spinner/>
        </div>}
    </>
  );
}

export function DrawPopup({ close }) {
  const navigate = useNavigate();

  const OnchangeDrawField = () => {
    window.dispatchEvent(new Event("cancel"));
    navigate("/app");
    document.getElementById("myNewId").style.cursor = "default";
  };

  return (
    <>
      {
        <div className="login-form">
          <div className="container-draw-popup-aoi">
            <AiOutlineClose
              size={"1.3em"}
              className="close-icon"
              onClick={close}
            />
            <div className="drawpopContainer">
              <div
                className=""
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "30px",
                  borderRadius: "10px",
                  color: "white",
                }}
              >
                <FiAlertTriangle
                  style={{ marginTop: "20px" }}
                  size={"2.7em"}
                  color="#f0b20a"
                />
                <div style={{ padding: "10px" }}>
                  <label>Are you sure you want to leave now?</label>
                </div>
                <div style={{ padding: "10px", color: "#6e7b7b" }}>
                  <p>
                    The contours you've drawn will not be saved, if you leave
                    now.
                  </p>
                </div>
                <div style={{ padding: "10px", display: "flex" }}>
                  <button
                    onClick={() => {
                      OnchangeDrawField();
                      close();
                    }}
                    className="btn-save"
                    style={{
                      backgroundColor: "rgb(154 162 169)",
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                  >
                    LEAVE NOW
                  </button>
                  <button
                    className="btn-save"
                    onClick={() => close()}
                    style={{ backgroundColor: "#4c99e7", cursor: "pointer" }}
                  >
                    BACK TO DRAWING
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );
}
