export const sowingConstants ={

    SowingbyFieldAllRequest : "SOWING_FIELD_REQUEST",
    SowingbyFieldAllSuccess : "SOWING_FIELD_SUCCESS",
    SowingbyFieldAllFailure : "SOWING_FIELD_FAILURE",
  
    CropbyIdAllRequest : "CROP_FIELD_REQUEST",
    CropbyIdAllSuccess : "CROP_FIELD_SUCCESS",
    CropbyIdAllFailure : "CROP_FIELD_FAILURE",
    
    TillagebyIdAllRequest : "TILLAGE_FIELD_REQUEST",
    TillagebyIdAllSuccess : "TILLAGE_FIELD_SUCCESS",
    TillagebyIdAllFailure : "TILLAGE_FIELD_FAILURE",
  
    IrrigationbyIdAllRequest : "IRRIGATION_FIELD_REQUEST",
    IrrigationbyIdAllSuccess : "IRRIGATION_FIELD_SUCCESS",
    IrrigationbyIdAllFailure : "IRRIGATION_FIELD_FAILURE",
  
    SowingGetAllRequest : "SOWING_GETALL_SEASON_REQUEST",
    SowingGetAllSuccess : "SOWING_GETALL_SEASON_SUCCESS",
    SowingGetAllFailure : "SOWING_GETALL_SEASON_FAILURE",

    IrrigationAllRequest : "IRRIGATION_GETALL_REQUEST",
    IrrigationAllSuccess : "IRRIGATION_GETALL_SUCCESS",
    IrrigationAllFailure : "IRRIGATION_GETALL_FAILURE",
  
    TillageAllRequest : "TILLAGE_GETALL_REQUEST",
    TillageAllSuccess : "TILLAGE_GETALL_SUCCESS",
    TillageAllFailure : "TILLAGE_GETALL_FAILURE",
  
    CropAllRequest : "CROP_GETALL_REQUEST",
    CropAllSuccess : "CROP_GETALL_SUCCESS",
    CropAllfailure : "CROP_GETALL_SUCCESS",

    CroptypeAllRequest : "CROPTYPE_GETALL_REQUEST",
    CroptypeAllSuccess : "CROPTYPE_GETALL_SUCCESS",
    CroptypeAllFailure : "CROPTYPE_GETALL_FAILURE",

    SowingAllRequest : "SOWING_GETALL_REQUEST",
    SowingAllSuccess : "SOWING_GETALL_SUCCESS",
    SowingAllFailure : "SOWING_GETALL_FAILURE",

    SowingDeleteAllRequest : "SOWING_DELETEALL_REQUEST",
    SowingDeleteAllSuccess : "SOWING_DELETEALL_SUCCESS",
    SowingDeleteAllFailure : "SOWING_DELETEALL_FAILURE",

    SowingDateAllRequest : "SOWING_DATE_GETALL_REQUEST",
    SowingDateAllSuccess : "SOWING_DATE_GETALL_SUCCESS",
    SowingDateAllFailure : "SOWING_DATE_GETALL_FAILURE",
  
  
} 