const Spinner = () =>{

    return (
        <>
         <div className="loader"></div>
        </>
    );

}

export default Spinner;