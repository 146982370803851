import { Jimp as JimpType, JimpConstructors,ImageCallback } from '@jimp/core';
import 'jimp';

declare const Jimp: JimpType & JimpConstructors & ImageCallback;

export const  validateBuffer = async (imageUrl: string) =>{
    try {
        const image = await Jimp.read(imageUrl);

        const { data, width, height } = image.bitmap;
        for (let y = 0; y < height; y++) {
          for (let x = 0; x < width; x++) {
            const idx = (y * width + x) << 2; 
            if (data[idx + 3] !== 0) { 
              return false; 
            }
          }
        } 
        return true;

      } catch (error) {
        return false; 
      }
}

export const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate() - 1).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
export const getFromDate = () =>{
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  export const FormatedArray = (data, chunkSize) => {
    const multiDimArray = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      const chunk = data.slice(i, i + chunkSize);
      multiDimArray.push(chunk);
    }
    return multiDimArray;
  }

  export function polygonToSvgPath(coordinates:any, mapWidth:any, mapHeight:any) {

    const formatArr = FormatedArray(coordinates, 2);
    const minLat = Math.min(...formatArr.map((coord:any) => coord[1]));
    const maxLat = Math.max(...formatArr.map((coord:any) => coord[1]));
    const minLon = Math.min(...formatArr.map((coord:any) => coord[0]));
    const maxLon = Math.max(...formatArr.map((coord:any) => coord[0]));

    const xScale = mapWidth / (maxLon - minLon);
    const yScale = mapHeight / (maxLat - minLat);

    const pathData = formatArr.map(([lon, lat]:any) => {
      const x = (lon - minLon) * xScale;
      const y = mapHeight - (lat - minLat) * yScale; // Invert y for SVG coordinate system
      return `${x},${y}`;
    }).join(' L ');

    return `M ${pathData} Z`; 
    }

    export function formatingCoordinate(split_coordinate){
        let result="", coordinst ="";
        for(let i=0;i<split_coordinate.length;i++){
          if(i%2 !== 0){
            result = result + coordinst.concat(split_coordinate[i],","); 
          }else{
            result = result + coordinst.concat(split_coordinate[i]," ");
          }
        }
        return result;
    }