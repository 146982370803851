import { fieldService } from '../Service/fieldServices';
import {put, call } from 'redux-saga/effects';
import { fieldConstants } from '../actions/constants/fieldConstants';

function* fieldall(request:any) {
    try {
        const response = yield call(fieldService.FieldService, request.payload); 
           
        console.log(response);
        

        if(response.status === 201){
            yield put({
                type : fieldConstants.FieldAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:fieldConstants.FieldAllFailure,
                payload: response.response.data.errorMessage
            });
        }      
      } catch (error) {

       

        yield put({
            type:fieldConstants.FieldAllFailure,
            payload: error
        });
      }
  }

  function* fielddelete(request:any) {
    try {
        const response = yield call(fieldService.FieldDelete, request.payload); 
        
        if(response.status === 200){
            yield put({
                type : fieldConstants.FieldDeleteAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:fieldConstants.FieldDeleteAllFailure,
                payload: response
            });
        }      
      } catch (error) {

        yield put({
            type:fieldConstants.FieldDeleteAllFailure,
            payload: error
        });
      }
  }


  function* fieldgroup(request:any) {
    try {
        const response = yield call(fieldService.Fieldgroup, request.payload); 
        
        if(response.status === 200){
            yield put({
                type : fieldConstants.FieldgroupAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:fieldConstants.FieldgroupAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:fieldConstants.FieldgroupAllFailure,
            payload: error
        });
      }
  }

  function* fieldgroupAll() {
    try {
        const response = yield call(fieldService.FieldgroupgetAll); 
        
        if(response.status === 200){
            yield put({
                type : fieldConstants.FieldgroupgetAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:fieldConstants.FieldgroupgetAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:fieldConstants.FieldgroupgetAllFailure,
            payload: error
        });
      }
  }

  function* fieldvoidAll(request:any) {
    try {
        const response = yield call(fieldService.Fieldvoid,request.payload); 
        
        
        if(response.status === 200){
            yield put({
                type : fieldConstants.LandVoidgetAllSuccess,
                payload : response.data.data[0].outputs.data.bands.B0.stats.mean
            });
        }else{
            yield put({
                type:fieldConstants.LandVoidgetAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:fieldConstants.LandVoidgetAllFailure,
            payload: error
        });
      }
  }

  function* fieldname(request:any) {
    try {
        const response = yield call(fieldService.Fieldname,request.payload); 
   
        
        if(response.status === 200){
            yield put({
                type : fieldConstants.FieldnamegetAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:fieldConstants.FieldnamegetAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:fieldConstants.FieldnamegetAllFailure,
            payload: error
        });
      }
  }

  function* fieldState(request:any) {
    try {
        const response = yield call(fieldService.FieldState,request.payload); 
        
        if(response.status === 200){
            yield put({
                type : fieldConstants.FieldStateAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:fieldConstants.FieldStateAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:fieldConstants.FieldStateAllFailure,
            payload: error
        });
      }
  }

  export const FieldSaga = {
    fieldall,fieldgroup,fieldgroupAll,fieldvoidAll,fieldname,fieldState,fielddelete
  }