import {useRef,useEffect} from 'react';
import Draw, { createBox } from 'ol/interaction/Draw';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import CircleStyle from 'ol/style/Circle.js';
import Fill from 'ol/style/Fill';
import GeoJSON from 'ol/format/GeoJSON';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import * as Constant from '../../common/constants';
import { useDispatch } from 'react-redux';
import { mapActions } from '../../actions/mapActions';


function DrawingShape ({map}){

    var drawInteractionRef = useRef(null);
    const patternToCheck = '/aoi/draw';
    const location = useLocation();
    const fieldshape = location.state;
    const dispatch = useDispatch();
    
    const baseVector = new VectorLayer({
        source: new VectorSource({
            format: new GeoJSON(),
        }),
        style: [
            new Style({
                fill: new Fill({
                    color: 'rgba(155, 187, 224, 0.3)'
                }),
                stroke: new Stroke({
                    color: 'rgba(255,255,255, 0.9)',
                    width: 6,
                }),
                image: new CircleStyle({
                    radius: 2,
                    fill: new Fill({
                        color: '#ffcc33'
                    })
                }),
            })
        ],
    });

     useEffect(()=>{

        const keyDownHandler = (event) => {    
            if (event.keyCode === 27) {
                if (window.location.pathname.includes(patternToCheck)){
                    document.getElementById('myNewId').style.cursor = 'default'
                    event.preventDefault();            
                     if(map !== null)
                     {
                       map.removeInteraction(drawInteractionRef.current); 
                     }
                }
            }        
        };  
        
        window.addEventListener('canceldrawing',()=>{
            if(map !== null){
                map.removeLayer(baseVector);
                map.removeInteraction(drawInteractionRef.current);                
            }
        });

        if(location.pathname.includes('/aoi/draw') )
        {
            const myElement = document.querySelector('.map-container');
            myElement.id = 'myNewId';
            document.getElementById('myNewId').style.cursor = 'crosshair';

            if(fieldshape.shape === 'Polygon'){
                AddingShape(fieldshape.shape);
                sessionStorage.setItem('shape','polygon');
                if(map !== null){
                    map.removeLayer(baseVector);
                    map.getLayers().getArray().filter(layer => layer instanceof VectorLayer).forEach(layer => map.removeLayer(layer));
                    map.addInteraction(drawInteractionRef.current);
                    map.addLayer(baseVector);
                }
            }
            else
            {
                AddingShape(fieldshape.shape);
                sessionStorage.setItem('shape','rectangle');
                if(map !== null){
                    map.removeLayer(baseVector);
                    map.getLayers().getArray().filter(layer => layer instanceof VectorLayer).forEach(layer => map.removeLayer(layer));
                    map.addInteraction(drawInteractionRef.current);
                    map.addLayer(baseVector);
                }
            }
        }else{

            if(!(location.pathname.includes('/draw'))){
                if(drawInteractionRef.current){
              
                    map.removeInteraction(drawInteractionRef.current);
                   const myElement = document.querySelector('.map-container');
                   myElement.id = 'myNewId';
                   document.getElementById('myNewId').style.cursor = 'default';
                   map.removeLayer(baseVector);  
                } 
            }

            
        }

        

        document.addEventListener('keydown', keyDownHandler);

        return  () =>{
            window.removeEventListener('canceldrawing', () => {});       
            document.removeEventListener('keydown',()=>{});
        }

     },[map, location])
    

    function AddingShape(typeSelected){

        let GeometryFunction, Shape;
        
        if (typeSelected === 'Polygon') {         
            GeometryFunction = null
            Shape = 'Polygon';
            RemoveInteraction();
        }
        if (typeSelected === 'Rectangle') {          
            GeometryFunction = createBox();
            Shape = 'Circle';
            RemoveInteraction();
        }
        if(typeSelected === 'none'){
            GeometryFunction = null
            Shape = 'Polygon';
            RemoveInteraction();
        }
        drawInteractionRef.current = new Draw({
            type: Shape,
            source: baseVector.getSource(),
            geometryFunction: GeometryFunction,
            style: [
                new Style({
                    stroke: new Stroke({
                        color: 'rgba(255, 191, 35, 0.5)',
                        width: 1.5
                    }),
                    fill: new Fill({
                        color: 'rgba(255, 191, 35, 0.5)'
                    }),
                    image: new CircleStyle({
                        radius: 6,
                        fill: new Fill({
                            color: 'rgba(255, 191, 35, 0.5)',
                        }),
                    }),
                })
            ],

        });                      
        drawInteractionRef.current.on('drawend', function (evt) {
            
            var changeSavebtn  = document.getElementById('save-btn') as HTMLButtonElement;
            map.removeInteraction(drawInteractionRef.current);               
            var featureData = evt.feature;  
            let output;
            document.getElementById('myNewId').style.cursor = 'default';

            if (featureData.getGeometry().getArea() > 10000) {
                output = Math.round((featureData.getGeometry().getArea() / 1000000) * 100) / 100 ;
            }else{
                output = Math.round(featureData.getGeometry().getArea() * 100) / 100 ;
            }         
            
            if(output <= 10000)
            {
                changeSavebtn.style.backgroundColor = '#4c99e7';             
                changeSavebtn.style.cursor = 'pointer';
            
                if(Shape === 'Polygon'){
                    sessionStorage.setItem("coordinate_poly", featureData.getGeometry().getCoordinates());
                }
                else{
                    sessionStorage.setItem("coordinate_rect", featureData.getGeometry().getCoordinates());
                }
                sessionStorage.setItem('extend_' , featureData.geometryChangeKey_.target.extent_);
                dispatch(mapActions.MapCoordinateData({coordinate : featureData.getGeometry().getCoordinates(), isState : true}));
            }
            else
            {                         
                toast.warn('You selected '+output+'ha area. Please reduce area size up to 10000 ha',{position: Constant.TOAST_POSITION, toastId :'success1'});
                dispatch(mapActions.MapCoordinateData({coordinate : "", isState : true}));
            }

            
        });

    }

    function RemoveInteraction()
    {
        if(map!== null)
        {
            map.removeInteraction(drawInteractionRef.current);
        } 
        baseVector.getSource().clear();
    } 

      return null;
}


export default DrawingShape;