import { mapReducer } from "./mapReducer";
import {layerReducer} from './layerReducer';
import { combineReducers } from 'redux';
import { signinReducer } from "./signinReducer";
import { favoriteReducer } from "./aoiReducer";
import { userareaReducer } from "./aoiReducer";
import { emailnotifyReducer } from "./emailnotifyReducer";
import { seasongetAllReducer,seasondeleteReducer, seasoncreateReducer} from "./seasonReducer";
import { fieldReducer,fieldgroupReducer,fieldgroupAllReducer,fieldLandReducer,fieldnameReducer, fieldstateReducer, fieldDeleteReducer} from "./fieldReducer";
import {sowingCropIdreducer,sowingTillIdreducer, irrigationIdreducer,Sowingallreducer,sowingTillreducer,sowingCropreducer,sowingReducer,
         sowingAllReducer,cropmatureReducer,SowingIdreducer } from "./sowingReducer";
import { mapstateReducer, mapCoordReducer, myfarmReducer } from "./mapstateReducer";
import { sowingdeleteReducer,sowingDateReducer } from "./sowingReducer";
import { cropdiseaseReducer,cropallReducer,growthReducer,growthallReducer } from "./cropIdentifyReducer";
import { emailfieldReducer } from "./emailnotifyReducer";



export const rootReducer = combineReducers({
    mapReducer,layerReducer,signinReducer,favoriteReducer,userareaReducer,emailnotifyReducer,sowingReducer, sowingTillreducer,sowingCropreducer,
    seasongetAllReducer,seasoncreateReducer,sowingAllReducer,cropmatureReducer,fieldReducer,fieldgroupReducer ,fieldgroupAllReducer, fieldLandReducer,fieldnameReducer,SowingIdreducer,
    sowingCropIdreducer,sowingTillIdreducer,irrigationIdreducer,Sowingallreducer,mapstateReducer,seasondeleteReducer,fieldstateReducer,sowingdeleteReducer,
    sowingDateReducer,cropdiseaseReducer,cropallReducer,fieldDeleteReducer,growthReducer,growthallReducer, mapCoordReducer, myfarmReducer,emailfieldReducer
}); 