import axios from 'axios';
import * as Constants from '../common/constants';

const instance = axios.create({
  baseURL: Constants.PROD_BASE_URL, 
  headers: {
    'Authorization': `Bearer ${Constants.TOKEN}`,
    'Content-Type': 'application/json', 
  },
});


export const instance1 = axios.create({
  baseURL: 'https://services.sentinel-hub.com/api/', 
  headers: {
    'Authorization': `Bearer ${Constants.SENTINEL_TOKEN}`,
    'Content-Type': 'application/json', 
  },
});


export const instance2 = axios.create({
  baseURL: Constants.PROD_BASE_URL, 
});

instance2.interceptors.request.use(function (config) {
  let token = JSON.parse(localStorage.getItem("AuthData"));
  config.headers["Authorization"] = "Bearer " + token.Token;
  return config;
});

export default instance;
  