import {put, call } from 'redux-saga/effects';
import { aoiServices } from "../Service/aoiService";
import { mapConstants } from "../actions/constants/mapConstants";

function* userareaSaga(request:any) {
    try {
        const response = yield call(aoiServices.userinfoGetAll, request.payload);

        if(response.status === 200){
            yield put({
                type:mapConstants.userareaGetAllSuccess,
                payload : response.data

            });
        }else{
            yield put({
                type:mapConstants.userareaGetAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {
        console.log("test saga", error);
        yield put({
            type:mapConstants.userareaGetAllFailure,
            payload: error
        });
      }
  }

  function* favoriteSaga(request:any) {

    console.log("fav:", request);
    

    try {
        const response = yield call(aoiServices.favoriteGetAll, request.payload);

        console.log(response);
        

        if(response.status === 200){
	      
            yield put({
                type:mapConstants.FavoriteGetAllSuccess,
                payload : response.data

            });
        }else{
            yield put({
                type:mapConstants.FavoriteGetAllFailure,
                payload: response.response.data.error
            });
        }      
      } catch (error) {
        
        yield put({
            type:mapConstants.FavoriteGetAllFailure,
            payload: error
        });
      }
  }


  export const aoiSaga = {
    userareaSaga, favoriteSaga
  }