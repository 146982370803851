import {takeLatest, all } from 'redux-saga/effects';
import { mapConstants } from '../actions/constants/mapConstants';
import { mapSaga } from './mapSaga';
import {layerSaga} from './layerSaga';
import {signinsaga} from './signSaga';
import { aoiSaga } from './aoiSaga';
import { emailsaga } from './emailnotifySaga';
import { sowingSaga } from './sowingSaga';
import { seasonsaga } from './seasonSaga';
import { FieldSaga } from './fieldSaga';
import {  Mapstate } from './MapstateSaga';
import { sowingConstants } from '../actions/constants/sowingConstants';
import { fieldConstants } from '../actions/constants/fieldConstants';
import { cropidentifyConstants } from '../actions/constants/cropIdentifyConstant';
import { diseaseIdentifySaga } from './cropIdentifySaga';


export default function* rootSaga() {
    yield all([
        /********* map saga ***********/
        takeLatest(mapConstants.SateliteGetAllRequest, mapSaga.sateliteGetAl),
        takeLatest(mapConstants.LayerGetAllRequest, layerSaga.layerGetAll),
        takeLatest(mapConstants.SigninGetAllRequest, signinsaga.signinSaga),
        takeLatest(mapConstants.FavoriteGetAllRequest, aoiSaga.favoriteSaga),
        takeLatest(mapConstants.userareaGetAllRequest, aoiSaga.userareaSaga),
        takeLatest(mapConstants.EmailGetAllRequest, emailsaga.emailnotifysaga),
        takeLatest(mapConstants.EmailFieldGetAllRequest, emailsaga.emailfieldnotifysaga),
        takeLatest(mapConstants.MapstateAllRequest,Mapstate.mapstate),     
       
        /*********** season saga ***********/
        takeLatest(mapConstants.SeasonAllRequest,seasonsaga.seasonSaga),
        takeLatest(mapConstants.SeasonGetallRequest,seasonsaga.seasonSagaGetall),
        takeLatest(mapConstants.SeasonDeleteAllRequest,seasonsaga.seasondeleteSaga),       
       
       
        /********** field saga ***********/
        takeLatest(fieldConstants.FieldAllRequest,FieldSaga.fieldall),
        takeLatest(fieldConstants.FieldgroupAllRequest,FieldSaga.fieldgroup),
        takeLatest(fieldConstants.FieldgroupgetAllRequest,FieldSaga.fieldgroupAll),
        takeLatest(fieldConstants.FieldnamegetAllRequest,FieldSaga.fieldname),
        takeLatest(fieldConstants.LandVoidgetAllRequest,FieldSaga.fieldvoidAll),
        takeLatest(fieldConstants.FieldStateAllRequest,FieldSaga.fieldState),
        takeLatest(fieldConstants.FieldDeleteAllRequest,FieldSaga.fielddelete),
       
        /*********** Sowing saga ***********/
        takeLatest(sowingConstants.CropbyIdAllRequest,sowingSaga.cropGetId),
        takeLatest(sowingConstants.TillagebyIdAllRequest,sowingSaga.tillagegetId),
        takeLatest(sowingConstants.IrrigationbyIdAllRequest,sowingSaga.irrigationGetId),
        takeLatest(sowingConstants.SowingGetAllRequest,sowingSaga.SowingAll),
        takeLatest(sowingConstants.SowingAllRequest,sowingSaga.sowingAll),
        takeLatest(sowingConstants.CroptypeAllRequest,sowingSaga.matureTypeCrop),
        takeLatest(sowingConstants.SowingDeleteAllRequest,sowingSaga.sowingDelete),
        takeLatest(sowingConstants.IrrigationAllRequest, sowingSaga.irrigationAll),
        takeLatest(sowingConstants.TillageAllRequest, sowingSaga.tillageAll),
        takeLatest(sowingConstants.CropAllRequest,sowingSaga.cropAll),
        takeLatest(sowingConstants.SowingbyFieldAllRequest,sowingSaga.SowingId),
        takeLatest(sowingConstants.SowingDateAllRequest, sowingSaga.sowingDaterange),

        /********* Crop Identify Saga */
        takeLatest(cropidentifyConstants.DiseasesIdentifyAllRequest, diseaseIdentifySaga.diseaseSaga),
        takeLatest(cropidentifyConstants.DiseasestableAllRequest,diseaseIdentifySaga.diseasetableSaga),
        takeLatest(cropidentifyConstants.GrowthIdentifyAllRequest,diseaseIdentifySaga.growthSaga),
        takeLatest(cropidentifyConstants.GrowthtableAllRequest,diseaseIdentifySaga.growthtableSaga)

 
    ]);
 }