import { instance2 } from "./http";

const layerGetAll = async () => {
  const response = await instance2.get('/layers/getAll').then(
    response => {
      return response;
    }
  ).catch(error => {
    console.log(error);
  });
  return response;
}

export const layerservices = {
  layerGetAll
}