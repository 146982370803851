
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Landing({ }) {

  const navigate = useNavigate();

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data === 'navigateToAbout') {
        navigate('/app');
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [navigate]);

  return (
      <div className='iframe-container' style={{width:'100%', height:'100vh',overflow:'hidden'}}>
        <iframe style={{width : '100%', height:'100%', border:'none'}} src={"/Landing.html"}/>
      </div>
  );
}

export default Landing;

