import { Feature, View } from "ol";
import VectorLayer from "ol/layer/Vector";
import Polygon from "ol/geom/Polygon";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import VectorSource from "ol/source/Vector";
import { useEffect, useState } from 'react';
import CircleStyle from 'ol/style/Circle.js';
import { useLocation, useNavigate } from "react-router-dom";
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import { useDispatch, useSelector } from 'react-redux';
import { mapActions } from '../../actions/mapActions';
import { fieldActions } from "../../actions/fieldActions";

function AreaOfInterest({ map }) {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const usersFromStore =  useSelector((state:any)=>state.emailnotifyReducer);
    const fieldemailstore = useSelector((state:any)=>state.emailfieldReducer.emailfielddata);
    const queryParams = new URLSearchParams(location.search);
    const fieldData = useSelector((state: any) => state.fieldnameReducer);
   
    const style = new Style({
        fill: new Fill({
            color: 'rgba(155, 187, 224, 0.3)'
        }),
        stroke: new Stroke({
            color: 'rgba(255,255,255, 0.9)',
            width: 6,
        }),
        image: new CircleStyle({
            radius: 2,
            fill: new Fill({
                color: '#ffcc33'
            })
        }),
    }); 

    const checkIfFieldExists = (fieldid : any) =>{
        return fieldData.fieldname?.some((field: any) => field._id === fieldid);
      }

      useEffect(() => {
        dispatch(fieldActions.Fieldname(localStorage.getItem('client_id')));
        
      }, []);

    useEffect(()=>{
      if(fieldemailstore) { 
        sessionStorage.setItem('cod', fieldemailstore.field_coordinates);
        sessionStorage.setItem('zoom', fieldemailstore.field_zoom);
        sessionStorage.setItem('field_extend', fieldemailstore.field_extend);
        sessionStorage.setItem('feature', 'myfarm');
  
        navigate(`/app/field/${fieldemailstore.field_name}`,
        {
              state:
              {
                name: fieldemailstore.field_name,
                coordinate: (fieldemailstore.field_coordinates).toString(),
                fieldId: fieldemailstore._id,
                groupname: (fieldemailstore.group_id == null) ? null : fieldemailstore.group_id.group_name
              }
        });
      }
    },[fieldemailstore])


    useEffect(()=>{
      if(location.pathname.includes('/filter') || location.pathname.includes('/field')){

        sessionStorage.setItem("coordinate_poly",location.state.coordinate);
        sessionStorage.setItem("aoi_extend", location.state.extend);

        const fieldId = location.state?.fieldId; 
        const fieldExists =  checkIfFieldExists(fieldId);

        var getcoordinates : any ;
        const strArray = location.state.coordinate.split(',');
        const numArray = strArray.map(Number);
        var intArray =  numArray ;
        const newArr = [];

        if (sessionStorage.getItem('feature') === 'myfarm') {
            getcoordinates = sessionStorage.getItem('cod');
        } else {
            getcoordinates = sessionStorage.getItem('coordinate_poly');
        }

        while (intArray.length) {
            newArr.push(intArray.splice(0, 2));
        }

        const shapefeatures = new Feature({
            geometry: new Polygon([newArr])
        });

        const vectorSource = new VectorSource({
            features: [shapefeatures],
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource, style: [style]
        });

        const polygonExtent = shapefeatures.getGeometry().getExtent();

        if (map !== null) 
        {
            clearExistingLayers(VectorLayer);
            map.addLayer(vectorLayer);
            map.setView(new View({
                center: newArr[0],
                zoom: sessionStorage.getItem('feature') === 'AOI' ? parseFloat(sessionStorage.getItem('Zoom-value')): parseFloat(sessionStorage.getItem('zoom')),
            }));
            map.getView().fit(polygonExtent, { padding: [100, 100, 100, 100] });
        }
    };

      
    },[location, map])

    useEffect(() => {
        if (usersFromStore && usersFromStore.emaildata) {
            CallingAOI(usersFromStore.emaildata[0].coordinates, usersFromStore.emaildata[0].zoom, usersFromStore.emaildata[0].extend);
        }
    }, [usersFromStore, map]);

    useEffect(() => {
        
        if (location.pathname.includes('/tracking')) {         
            if (queryParams.get('favid') !== null) {
                dispatch(mapActions.emailnotifyAll({ userId: queryParams.get('favid') }));
            }
            if (queryParams.get('field') !== null) {
                dispatch(mapActions.emailfieldnotifyAll({ value: queryParams.get('field') }));
            }
        }
       
    }, [location]); 

    
    function clearExistingLayers(layerType) {
        map.getLayers().getArray().filter(layer => layer instanceof VectorLayer).forEach(layer => map.removeLayer(layer));
    }

    useEffect(()=>{
        window.addEventListener('removeBaselayer',()=>{
            if(map!==null){
                map.getLayers().getArray().filter(layer => layer instanceof ImageLayer).forEach(layer => map.removeLayer(layer));
                map.getLayers().getArray().filter(layer => layer instanceof VectorLayer).forEach(layer => map.removeLayer(layer));
            }
        });
        return ()=>{
          window.removeEventListener('removeBaselayer',()=>{});
        }
    },[map])

   

    function CallingAOI(value, zoom, extend) {

        const newArr = [];
        let output = '';
        for (let i = 0; i < value.length; i += 2) {
            output += `${value[i]} ${value[i + 1]},`;
        }

        output = output.slice(0, -1);

        while (value.length) {
            newArr.push(value.splice(0, 2));
        }

        const shapefeatures = new Feature({
            geometry: new Polygon([newArr])
        });

        const vectorSource = new VectorSource({
            features: [
                shapefeatures
            ],
        });
        const polygonExtent = shapefeatures.getGeometry().getExtent();

        const imageLayer = new ImageLayer({
            source: new Static({
                url: 'https://services.sentinel-hub.com/ogc/wms/3b4df84a-65ef-4fa5-b795-eba75e754c1c?SERVICE=WMS&REQUEST=GetMap&VERSION=1.3.0&LAYERS=NATURAL-COLOR&MAXCC=40&WIDTH=512&HEIGHT=343&CRS=EPSG:3857&BBOX=' + extend +
                    '&geometry=POLYGON((' + output + '))&EVALSCRIPT=bGV0IG5kdmkgPSAoQjA4IC0gQjA0KSAvIChCMDggKyBCMDQpOwpyZXR1cm4gY29sb3JCbGVuZChuZHZpLAogICBbLTAuMiwgMCwgM'+
                    'C4xLCAwLjIsIDAuMywgMC40LCAwLjUsIDAuNiwgMC43LCAwLjgsIDAuOSwgMS4wIF0sCiAgIFtbMCwgMCwgMCxkYXRhTWFza10sCQkJCQkJCSAKICAgIFsxNjUvMjU1LDAsMzgvMjU1LGRhdGFNYXNrXSw'+
                    'gICAgICAgCiAgICBbMjE1LzI1NSw0OC8yNTUsMzkvMjU1LGRhdGFNYXNrXSwgCiAgICBbMjQ0LzI1NSwxMDkvMjU1LDY3LzI1NSxkYXRhTWFza10sICAKICAgIFsyNTMvMjU1LDE3NC8yNTUsOTcvMjU1LGRhdGFNYXNrX'+
                    'SwgCiAgICBbMjU0LzI1NSwyMjQvMjU1LDEzOS8yNTUsZGF0YU1hc2tdLCAKICAgIFsyNTUvMjU1LDI1NS8yNTUsMTkxLzI1NSxkYXRhTWFza10sCiAgICBbMjE3LzI1NSwyMzkvMjU1LDEzOS8yNTUsZGF0YU1hc2tdLCAKICA'+
                    'gIFsxNjYvMjU1LDIxNy8yNTUsMTA2LzI1NSxkYXRhTWFza10sCiAgICBbMTAyLzI1NSwxODkvMjU1LDk5LzI1NSxkYXRhTWFza10sICAKICAgIFsyNi8yNTUsMTUyLzI1NSw4MC8yNTUsZGF0YU1hc2tdLCAgCiAgICBbMCwxMDQvMjU1LDU1LzI1NSxkYXRhTWFza10gICAgICAgIAogICBdKTs=&QUALITY=70&TRANSPARENT=FALSE&time=' + queryParams.get('from') + '/' + queryParams.get('to'),
                imageExtent: extend.split(',').map(coord => parseFloat(coord)),
                projection: 'EPSG:32714',
            }),
        });

        const vectorLayer = new VectorLayer({
            source: vectorSource,
            style: [style],
        });

        if (map !== null) {
            clearExistingLayers(VectorLayer);
            clearExistingLayers(ImageLayer);
            map.addLayer(vectorLayer);
            map.addLayer(imageLayer);
            map.setView(new View({ center: newArr[0], zoom: parseFloat(zoom), }));
            if (polygonExtent && polygonExtent.length > 0) {
                map.getView().fit(polygonExtent, { padding: [100, 100, 100, 100] });
            } 
        }
    }

    return null;
}

export default AreaOfInterest;
