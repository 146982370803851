
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { DateTime } from "luxon";


function SeasonDetail({ callback, field }) {

    const [seasonState, setSeasonState] = useState(true);
    
    return (
        <div className='season-expansion-outer-container'>
            <div className="season-main-expansion-container" style={{ height: seasonState ? '75px' : 'auto' }} >
                <div className="season-forn-container">
                    <div className="season-expansion">
                        <div className="column">Crop</div>
                        {(field === "") ? (<a onClick={() => callback(true)} className="column">+Add crop</a>) :
                            (field && field !== "" && JSON.parse(field) && JSON.parse(field).sowing_crop_id ? (
                                <label className='label-season'>{JSON.parse(field).sowing_crop_id.crop_name}</label>
                            ) : null
                            )}
                    </div>
                    <div className="season-expansion" style={{ marginLeft: '15px' }}>
                        <div className="column">Sowing date</div>
                        {
                            (field && field !== "" && JSON.parse(field) && JSON.parse(field).sowing_date ? (
                                <label className='label-season'>{DateTime.fromISO(new Date(JSON.parse(field).sowing_date).toISOString()).toISODate()}</label>
                            ) : (<a onClick={() => callback(true)} className="column" >+Add</a>)
                            )
                        }
                    </div>
                    {seasonState ? <MdOutlineKeyboardArrowDown size={'1.8em'} onClick={() => setSeasonState(!seasonState)} /> : <MdOutlineKeyboardArrowUp size={'1.8em'} onClick={() => setSeasonState(!seasonState)} />}
                </div>
                <div className="season-forn-container">
                    <div className="season-expansion">
                        <div className="column">Maturity</div>
                        {
                            (field && field !== "" && JSON.parse(field) && JSON.parse(field).sowing_crop_type_id ? (
                                <label className='label-season'>{(JSON.parse(field).sowing_crop_type_id === null) ? "default" : JSON.parse(field).sowing_crop_type_id.crop_type_maturity}</label>
                            ) : (<a onClick={() => callback(true)} className="column" >+Add</a>)
                            )
                        }
                    </div>
                    <div className="season-expansion">
                        <div className="column" >Harvesting Date</div>
                        {
                            (field && field !== "" && JSON.parse(field) && JSON.parse(field).sowing_harvest_date ? (
                                <label className='label-season'>{DateTime.fromISO(new Date(JSON.parse(field).sowing_harvest_date).toISOString()).toISODate()}</label>
                            ) : (<a onClick={() => callback(true)} className="column" >+Add</a>)
                            )
                        }
                    </div>
                </div>
                <div className="season-forn-container">
                    <div className="season-expansion">
                        <div className="column">Irrigation type</div>
                        {
                            (field && field !== "" && JSON.parse(field) && JSON.parse(field).sowing_irrigation_type_id.irrigation_type_name ? (
                                <label className='label-season'>{JSON.parse(field).sowing_irrigation_type_id.irrigation_type_name}</label>
                            ) : (<a onClick={() => callback(true)} className="column" >+Add</a>)
                            )
                        }
                    </div>
                    <div className="season-expansion">
                        <div className="column">Tillage type</div>
                        {
                            (field && field !== "" && JSON.parse(field) && JSON.parse(field).sowing_tillage_type_id.tillage_type_name ? (
                                <label className='label-season'>{JSON.parse(field).sowing_tillage_type_id.tillage_type_name}</label>
                            ) : (<a onClick={() => callback(true)} className="column" >+Add</a>)
                            )
                        }
                    </div>
                </div>
                <div className="season-forn-container">
                    <div className="season-expansion    ">
                        <div className="column">Notes</div>
                        {
                            (field && field !== "" && JSON.parse(field) && JSON.parse(field).sowing_notes ? (
                                <label className='label-season'>{JSON.parse(field).sowing_notes}</label>
                            ) : (<a onClick={() => callback(true)} className="column" >+Add</a>))
                        }
                    </div>
                </div>
            </div>
        </div>
    );

}

export default SeasonDetail;