import { mapConstants } from "../actions/constants/mapConstants"

export const mapReducer = (state = {}, action) => {
  
    switch (action.type) {
        case mapConstants.SateliteGetAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case mapConstants.SateliteGetAllSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                sateliteData:action.payload
            }
        case mapConstants.SateliteGetAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }

        default : return state
    }
}