
import { layerservices } from "../Service/layerServices";
import {put, call } from 'redux-saga/effects';
import { mapConstants } from "../actions/constants/mapConstants";

function* layerGetAll() {
    try {
        const response = yield call(layerservices.layerGetAll);
        
        if(response.status === 200){
            yield put({
                type : mapConstants.LayerGetAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:mapConstants.LayerGetAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:mapConstants.LayerGetAllFailure,
            payload: error
        });
      }
  }

  export const layerSaga = {
   layerGetAll
  }