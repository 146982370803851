
import * as React from 'react';
import { Dispatch ,useEffect} from 'react';
import { connect} from 'react-redux';
import { useNavigate } from 'react-router-dom';


interface mapStates {
  dispatch: Dispatch<any>;
  userareaReducer: any;
}

 const Interest : React.FC<mapStates >  =({ userareaReducer }) => {

  const [value , setValue ] = React.useState(true);
  const navigate = useNavigate();
 
  useEffect(() => { 
    var divElement = document.getElementById('interest-submenu');
    var autoHeight = 'auto';
    var maxHeight = '150px'; 
    ( (localStorage.getItem('AccessToken') !== null && userareaReducer && userareaReducer.userareaData && userareaReducer.userareaData.length > 0)   ) ?  (divElement.style.height = (userareaReducer.userareaData.length > 4 ) ? maxHeight  : autoHeight) : <NotuserID/>;
  },[]);

  const clickAreaofInterest = (item) =>{
    
    sessionStorage.setItem('feature', 'AOI');  
    sessionStorage.setItem("Zoom-value",item.zoom);
    sessionStorage.setItem("aoi_extend",item.extend);
    sessionStorage.setItem('shapedata',item.shape);
    navigate(`/app/filter/${item.name}`,{state : { data : 'valid', coordinate : (item.coordinates).toString(), extend : item.extend}});
    window.dispatchEvent(new Event('removeBaselayer'))
    setValue(false);
  }
 
  return (
    <>
   { value && <div className="submenu" id="interest-submenu">     
          { (localStorage.getItem('AccessToken') !== null && userareaReducer && userareaReducer.userareaData && userareaReducer.userareaData.length > 0) ? 
          (<ul className="lis">
              { userareaReducer &&
                userareaReducer.userareaData &&
                userareaReducer.userareaData.map((item: any, i: any) => (
                  <li className="list-favorite" key={i} onClick={() => clickAreaofInterest(item)}>
                    {item.name}
                  </li>
                )) }
            </ul>): <NotuserID/>
          }
    </div> }
    </>
  );
}


const mapStateToProps = (state: any) => {
  const { userareaReducer } = state;
  
  return { userareaReducer };
};

export default connect(mapStateToProps)(Interest);


export const NotuserID = () =>{

  return (
    <div>
      <ul className='lis'>
          <li><label className='lab-text'></label></li>
          <li><label className='lab-text'></label></li>
          <li> <label className='lab-text'>Nothing Found...</label></li>
          <li><label className='lab-text'></label></li>
          <li><label className='lab-text'></label></li>           
        </ul>
    </div>
  )

}

