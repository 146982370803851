import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { transform } from 'ol/proj';
import 'ol/ol.css';
import { mapActions } from '../actions/mapActions';
import { ToastContainer } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import * as Constant from '../common/constants';


function MapView({ onMapRef }) {
    const ref = useRef<HTMLDivElement>(null);
    const mapRef = useRef<Map>(null);
    const dispatch = useDispatch();
    const Mapstate = useSelector((state: any) => state.mapstateReducer);
    const { pathname } = useLocation();

    useEffect(() => {
        if (ref.current && !mapRef.current) {
            const initialView = new View({
                center: transform([78.31130745616674, 22.472416382043946], 'EPSG:4326', 'EPSG:3857'),
                zoom: 5,
                maxZoom: 20
            });
            mapRef.current = new Map({
                layers: [new TileLayer({ source: new OSM({ url: Constant.MAPURL }) })],
                view: initialView,
                target: ref.current
            });
            onMapRef(mapRef.current);
        }
    }, [onMapRef, pathname]);

    useEffect(() => {
        dispatch(mapActions.layerGetAll());
        dispatch(mapActions.sateliteGetAll());
    }, [dispatch]);

    useEffect(() => {
        if (Mapstate.mapstate && mapRef.current) {
            mapRef.current.getView().setCenter(transform([78.31130745616674, 22.472416382043946], 'EPSG:4326', 'EPSG:3857'));
            mapRef.current.getView().setZoom(5);
        }
        mapRef.current.on('moveend', function (event) {
            sessionStorage.setItem('zoom-value', (event.frameState.viewState.zoom).toString());
            sessionStorage.setItem('Extend', (event.frameState.extent).toString());
        });

    }, [Mapstate, mapRef, pathname === '/']);

    return (
        <div className="map-container" ref={ref}>
            <ToastContainer />
        </div>
    );
}

export default MapView;