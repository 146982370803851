import { mapConstants } from "../actions/constants/mapConstants"

export const emailnotifyReducer = (state = {}, action) =>  {

   
    switch (action.type) {
        case mapConstants.EmailGetAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false,               
            }

        case mapConstants.EmailGetAllSuccess :     
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                emaildata : action.payload
            }
            
        case mapConstants.EmailGetAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }
        default : return state
    } 
}

export const emailfieldReducer = (state = {}, action) =>  {

   
    switch (action.type) {
        case mapConstants.EmailFieldGetAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false,               
            }

        case mapConstants.EmailFieldGetAllSuccess :     
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                emailfielddata : action.payload
            }
            
        case mapConstants.EmailFieldGetAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }
        default : return state
    }
}