
import { sowingallservices } from "../Service/sowingService";
import {put, call } from 'redux-saga/effects';
import { sowingConstants } from "../actions/constants/sowingConstants";

  function* irrigationAll() {
    try {
        const response = yield call(sowingallservices.irrigationGetAll);
        
        if(response.status === 200){
            yield put({
                type : sowingConstants.IrrigationAllSuccess,
                payload : response.data.resultObject
            });
        }else{
            yield put({
                type:sowingConstants.IrrigationAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:sowingConstants.IrrigationAllFailure,
            payload: error
        });
      }
  }

  function* irrigationGetId(request:any) {
    try {
        const response = yield call(sowingallservices.irrigationGetIdAll,request.payload);

        
       
        if(response.status === 200){
            yield put({
                type : sowingConstants.IrrigationbyIdAllSuccess,
                payload : response.data.resultObject
            });
        }else{
            yield put({
                type:sowingConstants.IrrigationbyIdAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:sowingConstants.IrrigationbyIdAllFailure,
            payload: error
        });
      }
  }

  function* tillageAll() {
    try {
        const response = yield call(sowingallservices.tillageGetAll);
        
        if(response.status === 200){
            yield put({
                type : sowingConstants.TillageAllSuccess,
                payload : response.data.resultObject
            });
        }else{
            yield put({
                type:sowingConstants.TillageAllSuccess,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:sowingConstants.TillageAllFailure,
            payload: error
        });
      }
  }

  function* tillagegetId(request :any) {
    try {
        const response = yield call(sowingallservices.tillageGetId,request.payload);

        if(response.status === 200){
            yield put({
                type : sowingConstants.TillagebyIdAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:sowingConstants.TillagebyIdAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:sowingConstants.TillagebyIdAllFailure,
            payload: error
        });
      }
  }

  function* cropAll() {
    try {
        const response = yield call(sowingallservices.cropGetAll);

        if(response.status === 200){
            yield put({
                type : sowingConstants.CropAllSuccess,
                payload : response.data.resultObject
            });
        }else{
            yield put({
                type:sowingConstants.CropAllSuccess,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:sowingConstants.CropAllfailure,
            payload: error
        });
      }
  }

  function* cropGetId(request:any) {
    try {
        const response = yield call(sowingallservices.cropGetId,request.payload);

        

        if(response.status === 200){
            yield put({
                type : sowingConstants.CropbyIdAllSuccess,
                payload : response.data.resultObject
            });
        }else{
            yield put({
                type:sowingConstants.CropbyIdAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:sowingConstants.CropbyIdAllFailure,
            payload: error
        });
      }
  }

  function* sowingAll(request:any) {
    try {
        const response = yield call(sowingallservices.sowingInsertAll, request.payload);
        
        if(response.status === 201){
            yield put({
                type : sowingConstants.SowingAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:sowingConstants.SowingAllFailure,
                payload: response
            });
        }      
      } catch (error) {

        yield put({
            type:sowingConstants.SowingAllFailure,
            payload: error
        });
      }
  }

  function* matureTypeCrop(request:any) {
    try {
        const response = yield call(sowingallservices.maturingCrop, request.payload);
        
        if(response.status === 200){
            yield put({
                type : sowingConstants.CroptypeAllSuccess,
                payload : response.data.resultObject
            });
        }else{
            yield put({
                type:sowingConstants.CroptypeAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:sowingConstants.CroptypeAllFailure,
            payload: error
        });
      }
  }

  function* SowingId(request:any) {
    try {
        const response = yield call(sowingallservices.sowingGetIdAll, request.payload);

        
        
        if(response.status === 200){
            yield put({
                type : sowingConstants.SowingbyFieldAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:sowingConstants.SowingbyFieldAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:sowingConstants.SowingbyFieldAllFailure,
            payload: error
        });
      }
  }

  function* SowingAll() {
    try {
        const response = yield call(sowingallservices.sowingGetAll);
 

        if(response.status === 200){
            yield put({
                type : sowingConstants.SowingGetAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:sowingConstants.SowingGetAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:sowingConstants.SowingGetAllFailure,
            payload: error
        });
      }
  }

  function* sowingDelete(request:any) {
    try {
        const response = yield call(sowingallservices.sowingDelete, request.payload);
        
        if(response.status === 200){
            yield put({
                type : sowingConstants.SowingDeleteAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:sowingConstants.SowingDeleteAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:sowingConstants.SowingDeleteAllFailure,
            payload: error
        });
      }
  }

  function* sowingDaterange(request:any) {
    try {
        const response = yield call(sowingallservices.sowingDaterange, request.payload);
        
        if(response.status === 200){
            yield put({
                type : sowingConstants.SowingDateAllSuccess,
                payload : response.data
            });
        }else{
            yield put({
                type:sowingConstants.SowingDateAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {

        yield put({
            type:sowingConstants.SowingDateAllFailure,
            payload: error
        });
      }
  }
  

  export const sowingSaga = {
    irrigationAll,tillageAll,cropAll,sowingAll,matureTypeCrop,SowingId,cropGetId,tillagegetId,irrigationGetId,SowingAll,sowingDelete,sowingDaterange
  }