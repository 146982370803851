import { mapConstants } from "../actions/constants/mapConstants"

export const layerReducer = (state = {}, action) => {
    
    switch (action.type) {
    
        case mapConstants.LayerGetAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
    
            case mapConstants.LayerGetAllSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                layerData:action.payload
            }
    
            case mapConstants.LayerGetAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }

        default : return state
    }
}