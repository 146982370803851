import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { FcClearFilters  } from "react-icons/fc";
import Calendar from '../../Component/SubmenuOption/Calendar';
import { useLocation, useNavigate } from 'react-router-dom';
import * as functions from '../../common/functions';
import { toast } from 'react-toastify';
import * as Constant from '../../common/constants';
import { AiOutlineClose } from 'react-icons/ai';
import { DrawPopup } from '../CropMonitor/FieldList';


function Filters ({}) {

  const [satellitevalue, setSatelliteValue] = useState('');
  const [layervalue, setlayervalue] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const data : any = location.state;
  const satellite = useSelector((state : any) => state.mapReducer.sateliteData);
  const layer = useSelector((state : any) => state.layerReducer.layerData);

  const [rangeDatefrom, setSelectedDate] = useState(functions.getFromDate());
  const [rangeDateto, setSelectedDateto] = useState(functions.getTodayDate());
  const [isDrawPopup, setDrawPop] = useState(false);

  useEffect(() => {
    if(!data) {
        navigate('/app');
    }
  }, [!data.layer]);
  
  useEffect(()=>{
   sessionStorage.setItem('layer',"");
  },[]);

  const handleChangeSatellite = (event) =>{
     setSatelliteValue(event.target.value);
  }
  const handleChangelayer = (event) =>{
    setlayervalue(event.target.value);
    sessionStorage.setItem('layer',event.target.value);
 }

  const handleDateChange = (newDate) => {       
      setSelectedDate(newDate);
  };

  const handleDatechangeto = (Date) =>{
     setSelectedDateto(Date);
  }

  const clearDateRange = () =>{
      setSelectedDate(''); setSelectedDateto('');
  }

  const handleChangecancel = () =>{
   setDrawPop(!isDrawPopup);
  }

  const onChangeSubmitfilter = () =>{
   if(sessionStorage.getItem('layer') === ""){
      toast.warn(Constant.ERROR_LAYER_SELECTION,{position:Constant.TOAST_POSITION});
      return;
   }
   if((rangeDatefrom < rangeDateto)){
         window.dispatchEvent(new Event('layer')); 
   }else{
      toast.warn(Constant.TOAST_DATE_RANGE,{position:Constant.TOAST_POSITION});
   }
  }

  return(
      <>
        <div className='side-panel-container'>
           <div className='side-panel sidepanel-filter'>
           <div
          onClick={handleChangecancel}
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <AiOutlineClose style={{ cursor: "pointer" }} />
        </div>
           <div className='nest-container-first' >                 
                  <label className="label-text-sowing">Source</label>
                  <div className="inputContainer">
                     <select className='custom-select' value={satellitevalue} onChange={handleChangeSatellite}>
                        {  satellite && satellite.map((item: any, i: any) => (<option value={item._id} key={i}>{item.satelliteName}</option>))}
                     </select>
                  </div>
               </div>
               <div className='nest-container-first' >                 
                  <label className="label-text-sowing">Layers</label>
                  <div className="inputContainer">
                     <select className='custom-select' value={layervalue} onChange={handleChangelayer}>
                       <option value="" disabled selected>select your option</option>
                        { layer && layer.map((item: any, i: any) => (<option value={item.layerName} key={i}>{item.layerName}</option>))}
                     </select>
                  </div>
               </div>              
               <Calendar selectedDate={rangeDatefrom} onDateChange={handleDateChange} rangeDateto={rangeDateto} onDateChangeto={handleDatechangeto} onChangeDateclear = {clearDateRange} />         
               <div className='sidepanel-tip sidepanel-tip-container' >
                       <h3 className=''>Choose Filter Option</h3>
                       <label className='tip-sidepanel'>Select the satellite source, layer, and date range to apply to your field.</label><br/>
                       <FcClearFilters style={{marginTop:'20px'}} size={'4.3em'}/>
                 </div>
               <div className='button-daterange'>
                  <button className='btn-save' onClick={onChangeSubmitfilter}>SUBMIT</button>
               </div>
           </div>
        </div>
        {isDrawPopup && <DrawPopup close={() => setDrawPop(!isDrawPopup)} feature={"filter"} />}
      </>
    );

}

export default Filters;