
import { useEffect, useState } from "react";
import { BiShapePolygon, BiShapeSquare } from 'react-icons/bi';
import { Tooltip } from 'react-tooltip'
import { useLocation, useNavigate } from 'react-router-dom';
import { mapActions } from '../../actions/mapActions';
import { useDispatch } from 'react-redux';

export default function Component(){
  
   const[isPolygon,setPolygon] = useState(true);
   const[isCursor, setCursor] = useState(false);
   const [isDrawPopup, setDrawPop] = useState(false);
   const navigate = useNavigate();
   const dispatch = useDispatch();
   const location = useLocation();
 

    useEffect(() => {   
        document.getElementById('main-menu').style.columnCount ='2'  
        document.getElementById('aoi-submenu').style.display = 'flex';       
        document.getElementById('aoi-id').style.padding = '6px';
        document.getElementById('main-menu').style.top = '-1%';  
        document.getElementById('polygon').style.color = 'white';
        document.getElementById('aoi-id-rectangle').style.padding = '6px';
    }, []);

    function actionPolygon(value)
    {   
        if(location.pathname.includes('/filter')){
            window.dispatchEvent(new Event('removeBaselayer'));
        }      

        setCursor(!isCursor);
        dispatch(mapActions.MapCoordinateData({coordinate : "", isState : true}))
        if(value === 'polygon'){   
            navigate('/app/aoi/draw',{state : { name : 'drawing', state : 'valid', shape : 'Polygon',}}); 
        }else{
            navigate('/app/aoi/draw',{state : { name : 'drawing', state : 'valid', shape : 'Rectangle'}});     
        }
        setPolygon(false);    
    }


    return(
      <>
      <div>     
    
      { isPolygon && <div className="submenu" id='main-menu'>  
      <a className='tool-tip-id' data-tooltip-id="my-tooltip" data-tooltip-content="polygon">
          <div className='aoi-main' id='aoi-id'>        
              <ul className='lis' id="aoi-submenu">
                  <li className='list-aoi' style={{cursor : isCursor ? 'crosshair' : " "}} onClick={()=>actionPolygon("polygon")}>
                      <BiShapePolygon id="polygon" data-tip="This is a tooltip"/>
                  </li>                  
              </ul>            
           </div>
           </a> 
           
           <a className='tool-tip-id' data-tooltip-id="my-tooltip" data-tooltip-content="square">
           <div className='aoi-main' id='aoi-id-rectangle'>
              <ul className='lis' id="aoi-submenu">
                <li className='list-aoi1' onClick={()=>actionPolygon("Rect")}>
                    <BiShapeSquare id="polygon" style={{color:'white'}} data-tip data-for="registerTip"/>
                </li>            
              </ul>
           </div>
           </a>         
          </div> }  
           <Tooltip id="my-tooltip" />  
        </div>     
        </>
    );
} 


  
 
  

 