import { fieldConstants } from "./constants/fieldConstants"

const FieldsaveAll = (data) =>{
    return{
        type : fieldConstants.FieldAllRequest,
        payload : data
    }
}

const FieldgroupAll = (data) =>{
    return{
        type : fieldConstants.FieldgroupAllRequest,
        payload : data
    }
}

const FieldgroupgetAll = () =>{
    return{
        type : fieldConstants.FieldgroupgetAllRequest,
    }
}

const FieldLandVoid = (data) =>{
    return{
        type : fieldConstants.LandVoidgetAllRequest,
        payload : data
    }
}

const Fieldname = (data) =>{
    return{
        type : fieldConstants.FieldnamegetAllRequest,
        payload : data
    }
}

const FieldState = (data) =>{
    return{
        type : fieldConstants.FieldStateAllRequest,
        payload : data
    }
}

const FieldDelete = (data) =>{

    console.log(data);
    

    return{
        type : fieldConstants.FieldDeleteAllRequest,
        payload : data
    }
}



export const fieldActions = {
    FieldsaveAll,FieldgroupAll,FieldgroupgetAll,FieldLandVoid,Fieldname,FieldState,FieldDelete
}