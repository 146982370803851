import { instance2 } from "./http";

const userinfoGetAll = async (data:any) =>  { 
  try{
    var dataOrg = {
      userId:data
    }; 
    const response = await instance2.post('/favorites/getById',  dataOrg);
    return response;
  }
  catch(error){
    console.log(error);
   }
  }


const favoriteGetAll = async (data:any) =>  {
 
    try{
          const response = await instance2.post('/favorites/create',  data);
              
          if(response.status === 200){
            window.dispatchEvent(new Event('callinterestAll')); 
          } 
          return response;
    }
    catch(error){
      return error;
    }
  }


  export const aoiServices = {
    userinfoGetAll, favoriteGetAll
  }