import Http from "./http";
import { instance2 } from "./http";

const emailAll = async (data:any) =>  {

  try{
        const response = await instance2.post('/favorites/getfavId',  data);
        return response;
  }
  catch(error){
    console.log(error);
  }
}

const emailfieldAll = async (data:any) =>  {

  try{
        const response = await instance2.post('/fields/getemailFieldId',  data);
        return response;
  }
  catch(error){
    console.log(error);
  }
}

export const emailServices = {
  emailAll, emailfieldAll
}