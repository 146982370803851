import { mapConstants } from "./constants/mapConstants"



const sateliteGetAll =()=>{
    return {
        type: mapConstants.SateliteGetAllRequest,
    }
}

const layerGetAll =()=>{
    return {
        type: mapConstants.LayerGetAllRequest,
    }
}

const signinAll = (data) =>{
    return {
        type: mapConstants.SigninGetAllRequest, 
        payload : data
    }
   
}

const seasonAll = (data) =>{
    return {
        type : mapConstants.SeasonAllRequest,
        payload : data
    }
}

const seasonGetAll = (data) =>{
    return {
        type : mapConstants.SeasonGetallRequest,
        payload : data
    }
}



const userareaAll = (data)=>{

    return {
        type: mapConstants.userareaGetAllRequest, 
        payload : data
    }
}

const favoriteAll = (data) => {
    return {    
        type: mapConstants.FavoriteGetAllRequest, 
        payload : data
    }
}

const emailnotifyAll = (data) =>{
    return {    
        type: mapConstants.EmailGetAllRequest, 
        payload : data
    }
}



const emailfieldnotifyAll = (data) =>{

    return {    
        type: mapConstants.EmailFieldGetAllRequest, 
        payload : data
    }
}

const SeasondeleteAll = (data) =>{
    return{
        type : mapConstants.SeasonDeleteAllRequest,
        payload : data
    }
}

const Mapstate = (data) =>{
    return {
        type : mapConstants.MapstateAllRequest,
        payload : data
    }
}

const MapData = () => {
    return {
        type : mapConstants.SentinelDataAllRequest,
    }
}

const MapCoordinateData = (data:any) =>{
    return{
        type : mapConstants.MapCoordinateAllSuccess,
        payload : data
    }
}

const Myfarmpanel = (data:any) =>{
    return{
        type : mapConstants.MyFarmPanelAllSuccess,
        payload : data
    }
}

export const mapActions = {
    sateliteGetAll, layerGetAll, signinAll, favoriteAll, userareaAll,emailnotifyAll,
    seasonAll, seasonGetAll,SeasondeleteAll,Mapstate, MapData, MapCoordinateData, Myfarmpanel,emailfieldnotifyAll
}