import { fieldConstants } from "../actions/constants/fieldConstants"


export const fieldReducer = (state = {}, action) => {

    switch (action.type) {
        case fieldConstants.FieldAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case fieldConstants.FieldAllSuccess:   
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                fieldData : action.payload
            }
        case fieldConstants.FieldAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                fieldDataerror : action.payload
            }

        default : return state
    }
}

export const fieldDeleteReducer = (state = {}, action) => {

    switch (action.type) {
        case fieldConstants.FieldDeleteAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case fieldConstants.FieldDeleteAllSuccess:   
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                fieldData : action.payload
            }
        case fieldConstants.FieldDeleteAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                fieldData : action.payload
            }

        default : return state
    }
}

export const fieldgroupReducer = (state = {}, action) => {

    switch (action.type) {
        case fieldConstants.FieldgroupAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case fieldConstants.FieldgroupAllSuccess:   
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                fieldgroup : action.payload
            }
        case fieldConstants.FieldgroupAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }

        default : return state
    }
}

export const fieldgroupAllReducer = (state = {}, action) => {

    switch (action.type) {
        case fieldConstants.FieldgroupgetAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case fieldConstants.FieldgroupgetAllSuccess:   
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                fieldgroupall : action.payload
            }
        case fieldConstants.FieldgroupgetAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }

        default : return state
    }
}

export const fieldLandReducer = (state = {}, action) => {

    switch (action.type) {
        case fieldConstants.LandVoidgetAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case fieldConstants.LandVoidgetAllSuccess:   
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                voidData : action.payload
            }
        case fieldConstants.LandVoidgetAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }

        default : return state
    }
}

export const fieldnameReducer = (state = {}, action) =>{

    switch (action.type) {
        case fieldConstants.FieldnamegetAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case fieldConstants.FieldnamegetAllSuccess:   
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                fieldname : action.payload
            }
        case fieldConstants.FieldnamegetAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }

        default : return state
    }

}

export const fieldstateReducer = (state = {}, action) => {

    switch (action.type) {
        case fieldConstants.FieldStateAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        case fieldConstants.FieldStateAllSuccess:   
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                fieldstate : action.payload
            }
        case fieldConstants.FieldStateAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }

        default : return state
    }
}