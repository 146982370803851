import { cropIdentifyservices } from '../Service/cropIdentifyServices';
import {put, call } from 'redux-saga/effects';
import { cropidentifyConstants } from '../actions/constants/cropIdentifyConstant';

function* diseaseSaga(request:any) {
    try {
        const response = yield call(cropIdentifyservices.diseasesAll, request.payload);

        if(response.status === 200){
            yield put({
                type:cropidentifyConstants.DiseasesIdentifyAllSuccess,
                payload : response.data

            });
        }else{
            yield put({
                type:cropidentifyConstants.DiseasesIdentifyAllFailure,
                payload: response
            });
        }      
      } catch (error) {
       
        yield put({
            type:cropidentifyConstants.DiseasesIdentifyAllFailure,
            payload: error
        });
      }
  }

  function* diseasetableSaga() {
    try {
        const response = yield call(cropIdentifyservices.diseasestableAll);

        if(response.status === 200){
            yield put({
                type:cropidentifyConstants.DiseasestableAllSuccess,
                payload : response.data

            });
        }else{
            yield put({
                type:cropidentifyConstants.DiseasestableAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {
       
        yield put({
            type:cropidentifyConstants.DiseasestableAllFailure,
            payload: error
        });
      }
  }


  function* growthSaga(request:any) {
    try {
        const response = yield call(cropIdentifyservices.growthAll, request.payload);

        if(response.status === 200){
            yield put({
                type:cropidentifyConstants.GrowthIdentifyAllSuccess,
                payload : response.data

            });
        }else{
            yield put({
                type:cropidentifyConstants.GrowthIdentifyAllFailure,
                payload: response
            });
        }      
      } catch (error) {
       
        yield put({
            type:cropidentifyConstants.GrowthIdentifyAllFailure,
            payload: error
        });
      }
  }

  function* growthtableSaga() {

    try {
        const response = yield call(cropIdentifyservices.growthtableAll);
        if(response.status === 200){
            yield put({
                type:cropidentifyConstants.GrowthtableAllSuccess,
                payload : response.data

            });
        }else{
            yield put({
                type:cropidentifyConstants.GrowthtableAllFailure,
                payload: response.status
            });
        }      
      } catch (error) {
       
        yield put({
            type:cropidentifyConstants.GrowthtableAllFailure,
            payload: error
        });
      }
  }


  export const diseaseIdentifySaga = {
    diseaseSaga,diseasetableSaga, growthtableSaga,growthSaga 
  }


  