import { access } from "fs"
import { mapConstants } from "../actions/constants/mapConstants"
import { sowingConstants } from "../actions/constants/sowingConstants"

export const sowingReducer = (state = {}, action) => {

    switch (action.type) {
    
        case sowingConstants.IrrigationAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
    
            case sowingConstants.IrrigationAllSuccess:
                return {
                    isLoading: false,
                    isSuccess: true,
                    isError: false, 
                    irrigationData : action.payload
               }
    
            case sowingConstants.IrrigationAllFailure:
                return {
                    isLoading: false,
                    isSuccess: false,
                    isError: true
                }
           

        default : return state
    }
}

export const sowingTillreducer = (state = {}, action) => {

    switch (action.type) {
    
       
            case sowingConstants.TillageAllRequest:
                return {
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                }
            
            case sowingConstants.TillageAllSuccess:
                return {
                    isLoading: false,
                    isSuccess: true,
                    isError: false, 
                    tillageData : action.payload
                }
            
            case sowingConstants.TillageAllFailure:
                return {
                    isLoading: false,
                    isSuccess: false,
                    isError: true
                }

        default : return state
    }
}

export const sowingTillIdreducer = (state = {}, action) => {

    switch (action.type) {
    
       
            case sowingConstants.TillagebyIdAllRequest:
                return {
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                }
            
            case sowingConstants.TillagebyIdAllSuccess:
                return {
                    isLoading: false,
                    isSuccess: true,
                    isError: false, 
                    tillageId : action.payload
                }
            
            case sowingConstants.TillagebyIdAllFailure:
                return {
                    isLoading: false,
                    isSuccess: false,
                    isError: true
                }

        default : return state
    }
}

export const sowingCropreducer = (state = {}, action) => {

    switch (action.type) {
    
       
            case sowingConstants.CropAllRequest:
                return {
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                }
            
            case sowingConstants.CropAllSuccess:
                return {
                    isLoading: false,
                    isSuccess: true,
                    isError: false, 
                    cropData : action.payload
                }
            
            case sowingConstants.CropAllfailure :
                return {
                    isLoading: false,
                    isSuccess: false,
                    isError: true
                }

        default : return state
    }
}
export const sowingCropIdreducer = (state = {}, action) => {

    switch (action.type) {

            case sowingConstants.CropbyIdAllRequest:
                return {
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                }
            
            case sowingConstants.CropbyIdAllSuccess:                
            return {
                    isLoading: false,
                    isSuccess: true,
                    isError: false, 
                    cropId : action.payload
                }
            
            case sowingConstants.CropbyIdAllFailure :
                return {
                    isLoading: false,
                    isSuccess: false,
                    isError: true
                }

        default : return state
    }
}

export const irrigationIdreducer = (state = {}, action) => {

    switch (action.type) {

            case sowingConstants.IrrigationAllRequest:
                return {
                    isLoading: true,
                    isSuccess: false,
                    isError: false
                }
            
            case sowingConstants.IrrigationbyIdAllSuccess:      
                      
                return {
                        isLoading: false,
                        isSuccess: true,
                        isError: false, 
                        irrigateId : action.payload
                    }
            
            case sowingConstants.IrrigationbyIdAllFailure :
                return {
                    isLoading: false,
                    isSuccess: false,
                    isError: true
                }

        default : return state
    }
}


export const sowingAllReducer = (state = {},action) =>{

    switch (action.type) {

        case sowingConstants.SowingAllRequest:
            
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        
        case sowingConstants.SowingAllSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                sowingData : action.payload
            }
        
        case sowingConstants.SowingAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                sowingDataerror : action.payload
            }

    default : return state
}


}

export const cropmatureReducer = (state = {},action) =>{

    switch (action.type) {
    
       
        case sowingConstants.CroptypeAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        
        case sowingConstants.CroptypeAllSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                matureData : action.payload
            }
        
        case sowingConstants.CroptypeAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }

    default : return state
}


}

export const SowingIdreducer = (state = {},action) =>{

    switch (action.type) {
    
       
        case sowingConstants.SowingbyFieldAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        
        case sowingConstants.SowingbyFieldAllSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                sowingfieldid : action.payload
            }
        
        case sowingConstants.SowingbyFieldAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                sowingfieldid : action.payload
            }

    default : return state
}


}

export const Sowingallreducer = (state = {},action) =>{

    switch (action.type) {
    
       
        case sowingConstants.SowingGetAllRequest:
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        
        case sowingConstants.SowingGetAllSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                sowing : action.payload
            }
        
        case sowingConstants.SowingGetAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true,
                sowingfieldid : action.payload
            }

    default : return state
}


}

export const sowingdeleteReducer = (state = {},action) =>{

    switch (action.type) {

        case sowingConstants.SowingDeleteAllRequest:
            
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        
        case sowingConstants.SowingDeleteAllSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                sowingDelete : action.payload
            }
        
        case sowingConstants.SowingDeleteAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }

    default : return state
    }
}

export const sowingDateReducer = (state={},action) =>{
    switch (action.type) {

        case sowingConstants.SowingDateAllRequest:
            
            return {
                isLoading: true,
                isSuccess: false,
                isError: false
            }
        
        case sowingConstants.SowingDateAllSuccess:
            return {
                isLoading: false,
                isSuccess: true,
                isError: false, 
                sowingDelete : action.payload
            }
        
        case sowingConstants.SowingDateAllFailure:
            return {
                isLoading: false,
                isSuccess: false,
                isError: true
            }

    default : return state
    }
}