import ImageLayer from "ol/layer/Image";
import Static from "ol/source/ImageStatic";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as Constant from "../../common/constants";
import * as functions from "../../common/functions";
import Spinner from "../../Container/spinner";

function LayerList({ map }) {

  var imageLayer;
  const [loading, setLoading] = useState(false);
  const Mapstate = useSelector((state: any) => state.mapstateReducer);

    useEffect(()=>  { 
        window.addEventListener('layer',async ()=>{
            if(map !== null){
                setLoading(true);
                var coordinate =  (sessionStorage.getItem('feature') === 'AOI') ? (sessionStorage.getItem('coordinate_poly')) : (sessionStorage.getItem('cod'));
                var extend =  (sessionStorage.getItem('feature') === 'AOI') ? (sessionStorage.getItem('aoi_extend')) : (sessionStorage.getItem('field_extend'));
                const layer = sessionStorage.getItem('layer');
                AddingLayers(coordinate, extend, layer);
            }                 
        });   
        return  () =>{
            window.removeEventListener('layer',()=>{});
        };
    },[map]);


    useEffect(()=>{
        if(map ){ 
            map.getLayers().getArray().filter(layer => layer instanceof ImageLayer).forEach(layer => map.removeLayer(layer));
        }
    },[Mapstate]);
    
    function AddingLayers(getCoordinate, extend, layer) { 
      

        if( localStorage.getItem('AccessToken') !== null ){

        const split_coordinate = getCoordinate.split(","), split_extend = extend.split(",");     
        let url=""; var evalscript = "";
        const result = functions.formatingCoordinate(split_coordinate);

        switch(layer) {
          case 'NDVI' :
              evalscript = Constant.evalScriptNDVI;
              break;
          case 'GNDVI' :
              evalscript = Constant.evalScriptGNDVI;
              break;
          case 'EVI' :
              evalscript = Constant.evalScriptEVI;
              break;
          case 'Natural Colour' :
              ClearLayer(); return;
          case 'SAVI' : 
              evalscript = Constant.evalScriptSAVI;
              break;
        }

        if(sessionStorage.getItem('shapedata') === 'rectangle') {
            url = Constant.baseUrl + '&MAXCC=20' + '&BBOX=' + extend + '&FORMAT=image/jpeg&' +  
             sessionStorage.getItem('from') + '/' + sessionStorage.getItem('to') + '&' + evalscript + Constant.quality;
        } else {
            url = Constant.baseUrl + '&SERVICE=WMS&VERSION=1.3.0&MAXCC=40&CRS=EPSG:3857' + '&BBOX=' + 
            extend + '&geometry=POLYGON((' + result.substring(0, result.length - 1) + '))&' + evalscript + Constant.quality + '&TRANSPARENT=FALSE&TIME=' + 
            sessionStorage.getItem('from') + '/' + sessionStorage.getItem('to');
        }

        functions.validateBuffer(url).then((result) => {
        
            if (result) {    
                toast.warn(Constant.TOAST_NDVI_ERROR,{position : Constant.TOAST_POSITION});
            } else {
                imageLayer =  new ImageLayer({
                    source: new Static({
                        url:url, imageExtent: [
                           parseInt(split_extend[0]),
                           parseInt(split_extend[1]),
                           parseInt(split_extend[2]),
                           parseInt(split_extend[3])], 
                           projection: 'EPSG:32714', 
                    }),
                });
                map.addLayer(imageLayer);
                setLoading(false);
                toast.success(Constant.TOAST_NDVI_SUCCESS,{position : Constant.TOAST_POSITION});
            }
            })
          }         
        else{
            toast.warn('Login to proceed',{position : Constant.TOAST_POSITION,});
        }         
    } 
    
  function ClearLayer() {
    if (map !== null) {
      setLoading(false);
      map
        .getLayers()
        .getArray()
        .filter((layer) => layer instanceof ImageLayer)
        .forEach((layer) => map.removeLayer(layer));
    }
  }
  return (
    <>
      {loading && (
        <div className="spinner-wrapper">
          <Spinner />
        </div>
      )}
    </>
  );
}

export default LayerList;
