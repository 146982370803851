export const cropidentifyConstants = {


    DiseasesIdentifyAllRequest : "DISEASE_IDENTIFY_GETALL_REQUEST",
    DiseasesIdentifyAllSuccess : "DISEASE_IDENTIFY_GETALL_SUCCESS",
    DiseasesIdentifyAllFailure : "DISEASE_IDENTIFY_GETALL_FAILURE",

    DiseasestableAllRequest : "DISEASE_TABLE_GETALL_REQUEST",
    DiseasestableAllSuccess : "DISEASE_TABLE_GETALL_SUCCESS",
    DiseasestableAllFailure : "DISEASE_TABLE_GETALL_FAILURE",

    GrowthIdentifyAllRequest : "GROWTH_IDENTIFY_GETALL_REQUEST",
    GrowthIdentifyAllSuccess : "GROWTH_IDENTIFY_GETALL_SUCCESS",
    GrowthIdentifyAllFailure : "GROWTH_IDENTIFY_GETALL_FAILURE",

    GrowthtableAllRequest : "GROWTH_TABLE_GETALL_REQUEST",
    GrowthtableAllSuccess : "GROWTH_TABLE_GETALL_SUCCESS",
    GrowthtableAllFailure : "GROWTH_TABLE_GETALL_FAILURE",

}