import  { useEffect } from 'react';
import { MousePosition } from 'ol/control.js';

const MousePositionControl = ({map}) => {

  useEffect(() => { 
    const coordinateFormat = function (coordinate) {
      const lon = coordinate[0].toFixed(4);
      const lat = coordinate[1].toFixed(4);
      return `<div style=" display: flex; flex-direction: column; align-items: flex-start; justify-content: space-between;  position: fixed ; bottom: 12px; left: 12px; color: #fff;padding: 10px;background: #000; width:160px;border-radius: 4px; ">
                <div style="display: flex; justify-content: space-between; margin-bottom: 5px; ">
                    <span style="margin-right: 5px;">Latitude:</span>
                    <p style="margin: 0;padding: 0;  ">${lat}</p>
                </div>
                <div style="display: flex; justify-content: space-between; margin-bottom: 5px; ">
                    <span style="margin-right: 5px;" >Longitude:</span>
                    <p style="margin: 0;padding: 0;  float:right; ">${lon}</p>
                </div>
            </div>`;
    };

    const mousePositionControl = new MousePosition({  //To hover over the mouse position
      coordinateFormat: coordinateFormat,
      projection: 'EPSG:4326',
      className: 'mouse-position',
      target: document.getElementById('mouse-position' ),
  });

  if (map) {
      map.addControl(mousePositionControl);
    return () => {      
      map.removeControl(mousePositionControl);
    };
  }
    
  }, [map && map !== null]);

  return null;
};

export default MousePositionControl;
